import {
  createStyles,
  ThemeIcon,
  Progress,
  Text,
  Group,
  Button,
  Paper,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ICON_SIZE = 60;

const useStyles = createStyles((theme) => ({
  card: {
    position: 'relative',
    overflow: 'visible',
    padding: theme.spacing.xl,
    paddingTop: theme.spacing.xl * 1.5 + ICON_SIZE / 3,
  },

  icon: {
    position: 'absolute',
    top: -ICON_SIZE / 3,
    left: `calc(50% - ${ICON_SIZE / 2}px)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },
}));

type ProgressCardProps = {
  icon: any;
  title: string;
  goal: string;
  count: number;
  goalCount: number;
  goalLabel: string;
  buttonText: string;
  buttonUrl: string;
};

export function ProgressCard({
  icon,
  title,
  goal,
  count,
  goalCount,
  goalLabel,
  buttonText,
  buttonUrl,
}: ProgressCardProps) {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const percent = Math.ceil((count / goalCount) * 100);

  return (
    <Paper radius="md" withBorder className={classes.card} mt={ICON_SIZE / 3}>
      <ThemeIcon className={classes.icon} size={ICON_SIZE} radius={ICON_SIZE}>
        <FontAwesomeIcon icon={icon} size="lg" />
      </ThemeIcon>

      <Text align="center" weight={700} className={classes.title}>
        {title}
      </Text>
      <Text color="dimmed" align="center" size="sm">
        {goal}
      </Text>

      <Group position="apart" mt="xs">
        <Text size="sm" color="dimmed">
          Progress
        </Text>
        <Text size="sm" color="dimmed">
          {String(percent) + '%'}
        </Text>
      </Group>

      <Progress value={percent} mt={5} />

      <Group position="apart" mt="md">
        <Text size="sm">
          {count} / {goalCount} {goalLabel}
        </Text>
        <Button variant="outline" onClick={() => navigate(buttonUrl)}>
          {buttonText}
        </Button>
      </Group>
    </Paper>
  );
}
