import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthStateInterface {
  token: string | null;
}
export const initialAuthState: AuthStateInterface = {
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    resetAuth: () => {
      return initialAuthState;
    },
  },
});
export const { setToken, resetAuth } = authSlice.actions;
export default authSlice.reducer;
export * from './selectors';
