import React, { useEffect, useState } from 'react';
import Page from '../../components/page';
import { useAuth0 } from '@auth0/auth0-react';
import { Title } from '@mantine/core';
import emvestApi from '../../utils/emvestApi';
import { Space, Loader, Button } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserInterface } from '@emvest/schema';
import { useAppDispatch } from '../../store/hooks';
import { setProfile, initialProfileState } from '../../store/profile';
import get from 'lodash/get';

// fetch user profile data, save to redux
// display skeleton loading screen
// redirect to either ?redirect= param, or to homepage
export const LoginSuccessPage = () => {
  const { isLoading, user, logout } = useAuth0();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // fetch user profile data, save to redux
    const fetchProfile = async () => {
      try {
        const response = await emvestApi.get('/users/profile', {});
        if (typeof response === 'object') {
          const profile: UserInterface = {
            ...initialProfileState,
            ...response,
          };
          if (get(profile, ['id'], null) !== null) {
            dispatch(setProfile(profile));
            setLoading(false);
          } else {
            throw new Error('User profile missing data');
          }
        } else {
          throw new Error('User profile not found');
        }
      } catch (err) {
        // TODO: gracefully handle
        console.error(err);
        setError(true);
      }
    };
    // only fetch the profile once the auth state is ready
    if (!isLoading && user) {
      fetchProfile();
    }
  }, [isLoading, user, dispatch]);

  useEffect(() => {
    // redirect to either ?redirect= param, or to homepage
    const redirectUser = () => {
      const params = new URLSearchParams(location.search);
      const redirectTarget = params.get('redirect') || '/';
      navigate(redirectTarget, { replace: true });
    };
    if (!loading && location) {
      redirectUser();
    }
  }, [loading, location, navigate]);

  // error fetching user profile
  if (error)
    return (
      <Page>
        <Title>Something went wrong</Title>
        <Space h="md" />
        <Button
          onClick={() => logout({ returnTo: window.location.origin })}
          variant={'light'}
        >
          Logout
        </Button>
      </Page>
    );

  // loading screen
  return (
    <Page>
      <Title>Logging in...</Title>
      <Space h="md" />
      <Loader />
    </Page>
  );
};
