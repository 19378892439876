import { useState } from 'react';
import { AppShell, Container, Space, useMantineTheme } from '@mantine/core';
import HeaderComponent from '../header';
import AdminSidebar from '../adminSidebar';
import { useAuth0 } from '@auth0/auth0-react';
import get from 'lodash/get';

export default function Page({ children }: any) {
  const { user } = useAuth0();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const userRoles = get(user, ['https://api.emvest.ai/roles'], []);
  const showSidebar = userRoles.includes('admin');

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      navbar={showSidebar ? <AdminSidebar opened={opened} /> : <></>}
      header={
        <HeaderComponent opened={opened} setOpened={setOpened} theme={theme} />
      }
    >
      <Container>
        <Space h="xl" />
        <Space h="xl" />
        {children}
        <div style={{ height: 400 }} />
      </Container>

      {/* <Text>Place footer here</Text> */}
    </AppShell>
  );
}
