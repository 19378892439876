import React, { useState, useEffect } from 'react';
import Page from '../../components/page';
import {
  Stack,
  Center,
  Group,
  Title,
  Text,
  Button,
  Badge,
  Space,
  Modal,
  Checkbox,
  Paper,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyePointer } from '@fortawesome/sharp-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { useSelector } from 'react-redux';
import {
  OnboardingStateInterface,
  updateOnboarding,
  getOnboarding,
} from '../../store/onboarding';
import { StatRings } from './statRings';

export const CampaignPage = () => {
  const onboardingData: OnboardingStateInterface = useSelector(getOnboarding);
  const { isLoading, isAuthenticated } = useAuth0();
  const [modalOpen, setModalOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // save provided campaign data to redux for future onboarding
    const params = new URLSearchParams(location.search);
    const campaignId = params.get('id') || '';
    const name = params.get('name') || '';
    const email = params.get('email') || '';
    if (campaignId !== '' || name !== '' || email !== '') {
      const onboarding: OnboardingStateInterface = {
        campaignId,
        name,
        email,
      };
      dispatch(updateOnboarding(onboarding));
    }
  }, [location, dispatch]);

  useEffect(() => {
    // redirect to dashboard if logged in
    if (!isLoading && isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isLoading, isAuthenticated, navigate]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setTermsAccepted(false);
  };
  const handleSignup = () => {
    navigate('/login?signup=true');
  };
  const handleLogin = () => {
    navigate('/login');
  };

  // skeleton landing page
  if (isLoading || isAuthenticated)
    return (
      <Page>
        <Title>Loading...</Title>
      </Page>
    );

  // generate messaging
  const { name } = onboardingData;
  const warmLead = Boolean(name !== '');
  let intro = <></>;
  if (warmLead) {
    intro = <b>{`Hi ${name.split(' ')[0]}, `}</b>;
  }
  const termsName = warmLead ? `${name}, t` : 'T';

  // campaign landing page
  return (
    <Page>
      <Modal
        opened={modalOpen}
        onClose={handleCloseModal}
        title={
          <Text size="xs" weight="bold">
            <Badge>Beta</Badge> Terms & conditions
          </Text>
        }
      >
        <Text>
          {termsName}hank you for your interest in testing the emvest.ai beta!
          Please accept these terms & conditions to signup for your free
          account.
        </Text>
        <Space h="sm" />
        <Paper p="md" withBorder>
          <Text size="xs">
            These are the terms & conditions... Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Nullam commodo mi ac eleifend varius.
            Pellentesque tempor scelerisque dolor, a euismod magna fringilla et.
            Praesent eget libero luctus, pellentesque libero nec, facilisis
            diam. Duis ornare orci at consequat posuere.
          </Text>
        </Paper>
        <Space h="xl" />
        <Checkbox
          data-autofocus
          checked={termsAccepted}
          label={'I accept these terms & conditions'}
          onChange={() => setTermsAccepted(!termsAccepted)}
        />
        <Space h="xl" />
        <Group position="right">
          <Button onClick={handleCloseModal} variant="default">
            Decline
          </Button>
          <Button onClick={handleSignup} disabled={!termsAccepted}>
            Signup for a free account
          </Button>
        </Group>
      </Modal>

      <Stack spacing="lg">
        <Center>
          <Group>
            <Title>Welcome to emvest.ai!</Title>
            <Badge>Beta</Badge>
          </Group>
        </Center>
        <Text align="center">
          {intro}emvest.ai empowers confident women through personalized
          investing education and recommendations. We believe that investing is
          an easier and smarter way for women to reach their life goals. As
          women seek to close the wage gap, they need a way to make up for the
          dollar difference today without another job or side hustle.
        </Text>

        <Space h="xs" />

        <StatRings
          data={[
            {
              label: 'control more',
              stats: '64% of women',
              progress: 64,
              color: 'green',
              description:
                'want to be more active in their finances, including investing decisions',
            },
            {
              label: 'know more',
              stats: '70% of women',
              progress: 70,
              color: 'blue',
              description:
                'say to invest they would need to know more about picking individual stocks',
            },
            {
              label: 'invest more',
              stats: '65% of women',
              progress: 65,
              color: 'red',
              description:
                "say they'd be more likely to invest more, if they had the clear steps to do so",
            },
          ]}
        />

        <Space h="xs" />

        <Center>
          <Group>
            <Text size="md" weight="600" align="center">
              Your Investing Success Story Starts Here
            </Text>
            <FontAwesomeIcon icon={faBullseyePointer} />
          </Group>
        </Center>

        <Center>
          <Group>
            <Button variant="default" onClick={handleLogin}>
              Login to existing account
            </Button>
            <Button onClick={() => setModalOpen(true)}>
              Signup for a FREE account
            </Button>
          </Group>
        </Center>
      </Stack>
    </Page>
  );
};
