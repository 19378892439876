import React, { useState } from 'react';
import {
  Alert,
  Stack,
  Space,
  Title,
  Button,
  Group,
  Breadcrumbs,
  Text,
  Anchor,
  Card,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import { useSelector } from 'react-redux';
import CheckboxGroup from '../../components/form/checkboxGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/sharp-solid-svg-icons';
import Page from '../../components/page';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emvestApi from '../../utils/emvestApi';
import { CreateFeedback } from '@emvest/schema';
import { useAppDispatch } from '../../store/hooks';
import { setCelebration } from '../../store/celebration';
import { questions } from './testQuestions';
import { scrollToTopNow } from '../../utils/scrollToTop';
import get from 'lodash/get';

interface IFormInput {
  a1: string[];
  a2: string[];
  a3: string[];
  a4: string[];
  a5: string[];
  a6: string[];
  a7: string[];
  a8: string[];
  a9: string[];
  a10: string[];
}
const schema = yup
  .object({
    a1: yup.array().required(),
    a2: yup.array().required(),
    a3: yup.array().required(),
    a4: yup.array().required(),
    a5: yup.array().required(),
    a6: yup.array().required(),
    a7: yup.array().required(),
    a8: yup.array().required(),
    a9: yup.array().required(),
    a10: yup.array().required(),
  })
  .required();

export const FormPage = () => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const a1 = watch('a1');
  const a2 = watch('a2');
  const a3 = watch('a3');
  const a4 = watch('a4');
  const a5 = watch('a5');
  const a6 = watch('a6');
  const a7 = watch('a7');
  const a8 = watch('a8');
  const a9 = watch('a9');
  const a10 = watch('a10');

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    // setLoading(true);
    scrollToTopNow();
    dispatch(
      setCelebration({
        open: true,
        title: `You've got the right stuff and then some!`,
        message: 'Feeling 🌶️? Take our Intermediate Quiz',
      })
    );

    // try {
    //   // send updated user data to API
    //   const feedbackArgs: CreateFeedback = {
    //     message: data.feedback,
    //   };
    //   const response = await emvestApi.post('/feedback', feedbackArgs);
    //   if (typeof response === 'object') {
    //     setSuccess(true);
    //     setValue('feedback', '');
    //     dispatch(
    //       setCelebration({
    //         open: true,
    //         title: `Thanks ${profile.firstName}!`,
    //         message:
    //           "Thanks for sending your feedback! We'll review it carefully.",
    //       })
    //     );
    //     // setTimeout(() => {
    //     //   navigate('/');
    //     // }, 3000);
    //   } else {
    //     throw new Error('Response is not an object');
    //   }
    // } catch (error) {
    //   console.error(error);
    //   setError(true);
    // }
    // setLoading(false);
  };

  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        <Text color="dimmed">Getting started quiz</Text>
      </Breadcrumbs>
      <Space h="xl" />
      <Group sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>"Getting started" quiz</Title>
        <Button variant="default" onClick={() => navigate('/')}>
          Back to dashboard
        </Button>
      </Group>
      <Space h="xl" />
      <Stack>
        <Text>Test your investing knowledge with this introductory quiz!</Text>
        <Text>
          <i>
            Don't worry about getting all the right answers - this quiz will
            help us learn what you already know so we can send you tailored
            educational content in the future.
          </i>
        </Text>
      </Stack>
      <Space h="xl" />

      {success && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faCircleCheck} />}
            title={`Thanks ${profile.firstName}!`}
            color="green"
            variant="outline"
          >
            Thanks for sending your feedback! You rock.
          </Alert>
          <Space h="md" />
          <Group>
            <Button variant="default" onClick={() => setSuccess(false)}>
              Submit more feedback
            </Button>
            <Button onClick={() => navigate('/')}>Back to dashboard</Button>
          </Group>
        </>
      )}

      {error && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
            title="Something went wrong"
            color="red"
          >
            Sorry for the inconvenience - please try again or reach out to our
            support team at support@emvest.ai.
          </Alert>
          <Space h="md" />
        </>
      )}

      {!success && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            {/* {questions.map((question, index) => {
              const errorMessage = get(errors, [question.id, 'message'], '');
              const questionId: 'a1' = String(question.id);

              return (
                <CheckboxGroup
                  key={index}
                  label={question.prompt}
                  // description={'Select all that apply'}
                  error={errorMessage}
                  options={question.options}
                  selections={a1}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue(String(question.id), selections);
                  }}
                />
              );
            })} */}

            <Stack>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[0].prompt}
                  error={errors.a1?.message}
                  options={questions[0].options}
                  selections={a1}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a1', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[1].prompt}
                  error={errors.a2?.message}
                  options={questions[1].options}
                  selections={a2}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a2', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[2].prompt}
                  error={errors.a3?.message}
                  options={questions[2].options}
                  selections={a3}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a3', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[3].prompt}
                  error={errors.a4?.message}
                  options={questions[3].options}
                  selections={a4}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a4', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[4].prompt}
                  error={errors.a5?.message}
                  options={questions[4].options}
                  selections={a5}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a5', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[5].prompt}
                  error={errors.a6?.message}
                  options={questions[5].options}
                  selections={a6}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a6', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[6].prompt}
                  error={errors.a7?.message}
                  options={questions[6].options}
                  selections={a7}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a7', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[7].prompt}
                  error={errors.a8?.message}
                  options={questions[7].options}
                  selections={a8}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a8', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[8].prompt}
                  error={errors.a9?.message}
                  options={questions[8].options}
                  selections={a9}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a9', selections);
                  }}
                />
              </Card>
              <Card p="lg" radius="md" withBorder>
                <CheckboxGroup
                  label={questions[9].prompt}
                  error={errors.a10?.message}
                  options={questions[9].options}
                  selections={a10}
                  min={1}
                  max={1}
                  onChange={(selections: string[]) => {
                    setValue('a10', selections);
                  }}
                />
              </Card>
            </Stack>

            <Group position="right" mt="md">
              <Button type="submit" loading={loading}>
                Finish quiz
              </Button>
            </Group>
          </Stack>
        </form>
      )}
    </Page>
  );
};
