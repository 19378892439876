import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/page';
import {
  SimpleGrid,
  Card,
  Text,
  Space,
  Title,
  Stack,
  Skeleton,
  Alert,
  Breadcrumbs,
  Anchor,
} from '@mantine/core';
import emvestApi from '../../utils/emvestApi';
import { FeedbackInterface, ReadFeedbacks } from '@emvest/schema';
import format from 'date-fns/format';
import get from 'lodash/get';
import { UserSummary } from '../../components/userSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSnooze,
  faExclamationTriangle,
} from '@fortawesome/sharp-solid-svg-icons';

export const PlatformFeedbackPage = () => {
  const [feedbacks, setFeedbacks] = useState<FeedbackInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const args: ReadFeedbacks = {
          sortBy: 'created',
          sortOrder: 'DESC',
          limit: 100,
        };
        const response = await emvestApi.get('/feedback', args);
        const results = get(response, ['results'], []);
        setFeedbacks(results);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoading(false);
    };
    fetchFeedback();
  }, []);

  const gotoUserProfile = (userId: string) => {
    navigate(`/admin/users/${userId}`);
  };

  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        <Text color="dimmed">Platform feedback</Text>
      </Breadcrumbs>
      <Space h="xl" />
      <Title>Platform feedback</Title>
      <Space h="xl" />

      <SimpleGrid cols={2}>
        {feedbacks.map((feedback, index) => {
          const feedbackDate = new Date(feedback.created);
          console.log(feedbackDate);
          const user = feedback.user;

          return (
            <Card key={index} p="lg" radius="md" withBorder>
              <Card.Section>
                <UserSummary
                  user={user}
                  fullName={true}
                  onClick={() => gotoUserProfile(user.id)}
                  fullWidth={true}
                />
              </Card.Section>
              <Space h="md" />
              <Stack>
                <Text weight={500}>"{feedback.message}"</Text>
                <Text size="sm" color="dimmed" align="right">
                  {format(feedbackDate, 'MMMM do, yyyy')}
                </Text>
              </Stack>
            </Card>
          );
        })}
      </SimpleGrid>

      {loading && <Skeleton animate={true} height={54} width="100%" />}
      {!loading && !error && !feedbacks.length && (
        <>
          <Space h="xl" />
          <Alert
            title="No feedback found"
            color="yellow"
            variant="outline"
            icon={<FontAwesomeIcon icon={faSnooze} />}
          >
            Please check back later
          </Alert>
        </>
      )}
      {error && (
        <>
          <Space h="xl" />
          <Alert
            title="Something went wrong"
            color="red"
            variant="outline"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          >
            Please check back later
          </Alert>
        </>
      )}
    </Page>
  );
};
