import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColorScheme } from '@mantine/core';

export interface InterfaceStateInterface {
  themeColor: ColorScheme;
}
export const initialInterfaceState: InterfaceStateInterface = {
  themeColor: 'light',
};

export const interfaceSlice = createSlice({
  name: 'interface',
  initialState: initialInterfaceState,
  reducers: {
    setThemeColor: (state, action: PayloadAction<ColorScheme>) => {
      state.themeColor = action.payload;
    },
    toggleThemeColor: (state) => {
      state.themeColor = state.themeColor === 'light' ? 'dark' : 'light';
    },
    resetInterface: () => {
      return initialInterfaceState;
    },
  },
});
export const { setThemeColor, toggleThemeColor, resetInterface } =
  interfaceSlice.actions;
export default interfaceSlice.reducer;
export * from './selectors';
