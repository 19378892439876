import React, { useState } from 'react';
import Page from '../../components/page';
// import { useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  Stack,
  Space,
  Title,
  Button,
  TextInput,
  Group,
  Breadcrumbs,
  Text,
  Anchor,
} from '@mantine/core';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/sharp-solid-svg-icons';
import { useSelector } from 'react-redux';
import {
  ProfileStateInterface,
  getProfile,
  initialProfileState,
  setProfile,
} from '../../store/profile';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emvestApi from '../../utils/emvestApi';
import { UserInterface, UpdateUser } from '@emvest/schema';
import { useAppDispatch } from '../../store/hooks';
import { setCelebration } from '../../store/celebration';
import { PhoneInput } from '../../components/form/phoneInput';
import get from 'lodash/get';

interface IFormInput {
  firstName: string;
  lastName: string;
  phone?: number;
  photo: string;
}
const schema = yup
  .object({
    firstName: yup.string().required('Please enter your first name'),
    lastName: yup.string(),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(10, 'Please enter a valid phone number')
      .max(10, 'Please enter a valid phone number'),
    photo: yup.string(),
  })
  .required();

export const SettingsPage = () => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const profilePhone = get(profile, ['phone'], undefined);
  const phoneDefault = profilePhone ? Number(profilePhone) : undefined;
  const defaults: IFormInput = {
    firstName: profile.firstName,
    lastName: profile.lastName,
    phone: phoneDefault,
    photo: profile.photo,
  };
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    defaultValues: defaults,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);

    try {
      // send updated user data to API
      const phoneNumber = String(get(data, ['phone'], ''));
      const feedbackArgs: UpdateUser = {
        id: profile.id,
        ...data,
        phone: phoneNumber === '0' ? '' : phoneNumber,
      };
      const response = await emvestApi.put('/users', feedbackArgs);
      if (typeof response === 'object') {
        setSuccess(true);
        const updatedUser: UserInterface = {
          ...initialProfileState,
          ...response,
        };
        dispatch(setProfile(updatedUser));
        dispatch(
          setCelebration({
            open: true,
            title: `Success!`,
            message: 'Your account has been updated successfully.',
          })
        );
      } else {
        throw new Error('Response is not an object');
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  const phone = watch('phone');

  // settings
  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        <Text color="dimmed">My account</Text>
      </Breadcrumbs>
      <Space h="xl" />
      <Title>My account</Title>
      <Space h="xl" />

      {success && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faCircleCheck} />}
            title={`Account updated successfully!`}
            color="green"
            variant="outline"
          >
            There may be a delay before account updates are reflected.
          </Alert>
          <Space h="md" />
        </>
      )}

      {error && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
            title="Something went wrong"
            color="red"
          >
            Sorry for the inconvenience - please try again or reach out to our
            support team at support@emvest.ai.
          </Alert>
          <Space h="md" />
        </>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <TextInput
            label="First name"
            disabled={loading}
            withAsterisk
            error={errors.firstName?.message}
            {...register('firstName')}
          />
          <TextInput
            label="Last name"
            disabled={loading}
            error={errors.lastName?.message}
            {...register('lastName')}
          />
          <PhoneInput
            label="Phone number"
            description="Optional, in case you want to be contacted via text message"
            value={phone}
            setValue={setValue}
            setValueProperty={'phone'}
            loading={loading}
            errorMessage={errors.phone?.message}
          />
          <TextInput
            label="Photo URL (beta)"
            description="Enter the URL of an externally hosted photo (e.g. from Facebook) to customize your profile image"
            disabled={loading}
            error={errors.photo?.message}
            {...register('photo')}
          />

          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Save changes
            </Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
