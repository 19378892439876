import { resetAuth } from './auth';
import { resetProfile } from './profile';
import { resetForms } from './forms';
import { resetCelebration } from './celebration';

const resetRedux = (dispatch: any) => {
  dispatch(resetAuth());
  dispatch(resetProfile());
  dispatch(resetForms());
  dispatch(resetCelebration());
};

export default resetRedux;
