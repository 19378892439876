import React, { useState } from 'react';
import {
  createStyles,
  Header,
  Container,
  MediaQuery,
  Burger,
  Button,
  Group,
  Image,
  Menu,
} from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../../store/hooks';
import { useSelector } from 'react-redux';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import { toggleThemeColor } from '../../store/interface';
import { getThemeColor } from '../../store/interface';
import { useNavigate } from 'react-router-dom';
import emvestLogo1 from './emvestLogo1.png';
import emvestLogo1White from './emvestLogo1White.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGear,
  faArrowRightFromBracket,
  faMoon,
  faBrightness,
  faComments,
} from '@fortawesome/sharp-solid-svg-icons';
import { UserSummary } from '../userSummary';
import get from 'lodash/get';

type HeaderProps = {
  opened: boolean;
  setOpened: any;
  theme: any;
};

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    maxWidth: '100%',
    padding: 0,
  },
  burger: {
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },
  links: {
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },
  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
  userLoggedOut: {
    display: 'inline-block',
    // width: '100%',
    padding: theme.spacing.md,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.colors.gray[0],
    },
  },
}));

const HeaderComponent = ({ opened, setOpened, theme }: HeaderProps) => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const themeColor = useSelector(getThemeColor);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const { user, isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate();
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const userRoles = get(user, ['https://api.emvest.ai/roles'], []);
  const isDev = userRoles.includes('dev');

  const toggleAppTheme = async () => {
    dispatch(toggleThemeColor());
  };

  const openAccountMenu = () => {
    setAccountMenuOpen(!accountMenuOpen);
  };

  return (
    <Header height={70} p="md">
      <Container className={classes.header}>
        <Group>
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Burger
              opened={opened}
              onClick={() => setOpened((o: boolean) => !o)}
              size="sm"
              color={theme.colors.gray[6]}
              className={classes.burger}
              mr="xl"
            />
          </MediaQuery>
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <Link to="/">
              {themeColor === 'light' ? (
                <Image
                  src={emvestLogo1}
                  alt="emvest.ai"
                  height={28}
                  width={150}
                />
              ) : (
                <Image
                  src={emvestLogo1White}
                  alt="emvest.ai"
                  height={28}
                  width={150}
                />
              )}
            </Link>
          </MediaQuery>
        </Group>

        {!isAuthenticated && (
          <Button
            onClick={() => navigate('/login')}
            className={classes.link}
            variant={'default'}
          >
            Login
          </Button>
        )}

        {isAuthenticated && (
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <div>
                <UserSummary user={profile} onClick={openAccountMenu} />
              </div>
            </Menu.Target>

            <Menu.Dropdown>
              {profile.onboardingComplete && (
                <>
                  <Menu.Label>My account</Menu.Label>
                  <Menu.Item
                    onClick={() => navigate('/settings')}
                    rightSection={<FontAwesomeIcon icon={faGear} />}
                  >
                    Settings
                  </Menu.Item>
                </>
              )}
              {isDev && (
                <Menu.Item
                  onClick={toggleAppTheme}
                  rightSection={
                    <FontAwesomeIcon
                      icon={themeColor === 'light' ? faMoon : faBrightness}
                    />
                  }
                >
                  {themeColor === 'light' ? 'Dark mode' : 'Light mode'}
                </Menu.Item>
              )}
              {(isDev || profile.onboardingComplete) && <Menu.Divider />}

              {profile.onboardingComplete && (
                <Menu.Item
                  onClick={() => navigate('/feedback')}
                  rightSection={<FontAwesomeIcon icon={faComments} />}
                >
                  Send feedback
                </Menu.Item>
              )}
              <Menu.Item
                onClick={() =>
                  logout({ returnTo: window.location.origin + '/login' })
                }
                rightSection={
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                }
              >
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Container>
    </Header>
  );
};

export default HeaderComponent;
