import { NumberInput } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/sharp-solid-svg-icons';

type SliderInputProps = {
  label: string;
  description: string;
  value: number | undefined;
  setValue: any;
  setValueProperty: string;
  loading: boolean;
  errorMessage: string | undefined;
};

export const PhoneInput = (props: SliderInputProps) => {
  const {
    label,
    description,
    value,
    setValue,
    setValueProperty,
    loading,
    errorMessage,
  } = props;

  return (
    <NumberInput
      label={label}
      description={description}
      placeholder="(111) 222-3333"
      value={value}
      onChange={(val: number) => setValue(setValueProperty, val)}
      parser={(val) => (val || '').replace(/\$\s?|(,*)/g, '')}
      formatter={(val) =>
        String(val)
          .replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      }
      step={0}
      disabled={loading}
      icon={<FontAwesomeIcon icon={faPhone} />}
      error={errorMessage}
    />
  );
};
