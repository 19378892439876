import React, { useState } from 'react';
import {
  Alert,
  Stack,
  Space,
  Title,
  Button,
  TextInput,
  Group,
} from '@mantine/core';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import {
  OnboardingStateInterface,
  getOnboarding,
} from '../../store/onboarding';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
  faUserVneckHairLong,
  faSignature,
  faEnvelope,
} from '@fortawesome/sharp-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emvestApi from '../../utils/emvestApi';
import { UserInterface, UpdateUser } from '@emvest/schema';
import { PhoneInput } from '../../components/form/phoneInput';
import { useAppDispatch } from '../../store/hooks';
import { setProfile, initialProfileState } from '../../store/profile';
import { resetOnboarding } from '../../store/onboarding';
import get from 'lodash/get';

interface IFormInput {
  firstName: string;
  lastName?: string;
  email: string;
  phone?: number;
}
const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string(),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(10, 'Please enter a valid phone number')
      .max(10, 'Please enter a valid phone number'),
  })
  .required();

export const OnboardingStepOne = () => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const onboarding: OnboardingStateInterface = useSelector(getOnboarding);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({ mode: 'onBlur', resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);

    try {
      // send updated user data to API
      const campaignId = get(onboarding, ['campaignId'], null);
      const phoneNumber = String(get(data, ['phone'], ''));
      const updateArgs: UpdateUser = {
        id: profile.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: phoneNumber === '0' ? '' : phoneNumber,
        onboardingStep: Math.max(2, profile.onboardingStep),
      };
      if (campaignId) {
        updateArgs['campaignId'] = campaignId;
      }
      const response = await emvestApi.put('/users', updateArgs);
      if (typeof response === 'object') {
        // save updated data to redux
        const updatedUser: UserInterface = {
          ...initialProfileState,
          ...response,
        };
        dispatch(setProfile(updatedUser));

        // update onboarding data
        dispatch(resetOnboarding());

        // proceed to next step
        navigate('/onboarding/2');
      } else {
        throw new Error('Response is not an object');
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  // auto-fill any info gathered from campaign landing page
  // prefer any new info from user login (e.g. social media info)
  const name = get(onboarding, ['name'], '').trim();
  const nameSplit = name.split(' ');
  const firstName =
    get(profile, ['firstName'], null) || get(nameSplit, [0], '');
  const lastName = get(profile, ['lastName'], null) || get(nameSplit, [1], '');
  const email = get(profile, ['email'], null) || get(onboarding, ['email'], '');
  const profilePhone = get(profile, ['phone'], undefined);
  const phone = profilePhone ? Number(profilePhone) : undefined;

  // Step 1: account details
  return (
    <>
      <Title order={2}>Account details</Title>
      <Space h="md" />

      {error && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
            title="Something went wrong"
            color="red"
          >
            Sorry for the inconvenience - please try again or reach out to our
            support team at support@emvest.ai.
          </Alert>
          <Space h="md" />
        </>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <TextInput
            withAsterisk
            label="First name"
            placeholder="First name"
            defaultValue={firstName}
            disabled={loading}
            icon={<FontAwesomeIcon icon={faUserVneckHairLong} />}
            error={errors.firstName?.message}
            {...register('firstName')}
          />
          <TextInput
            label="Last name"
            placeholder="Last name"
            defaultValue={lastName}
            disabled={loading}
            error={errors.lastName?.message}
            icon={<FontAwesomeIcon icon={faSignature} />}
            {...register('lastName')}
          />

          <PhoneInput
            label="Phone number"
            description="Optional, in case you want to be contacted via text message"
            value={phone}
            setValue={setValue}
            setValueProperty={'phone'}
            loading={loading}
            errorMessage={errors.phone?.message}
          />

          <TextInput
            withAsterisk
            label="Email"
            placeholder="Email"
            defaultValue={email}
            disabled={loading}
            icon={<FontAwesomeIcon icon={faEnvelope} />}
            error={errors.email?.message}
            {...register('email')}
          />

          <Group position="right" mt="md">
            <Button type="submit" loading={loading}>
              Next step
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};
