import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Page from '../../components/page';
import {
  Center,
  Stack,
  Grid,
  SimpleGrid,
  Card,
  Text,
  Space,
  Title,
  Skeleton,
  Alert,
  Breadcrumbs,
  Anchor,
  Button,
  Group,
} from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import emvestApi from '../../utils/emvestApi';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import { useSelector } from 'react-redux';
import { ROLE_ADMIN, ReportInterface, ReadReports } from '@emvest/schema';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnooze, faLightbulb } from '@fortawesome/sharp-solid-svg-icons';
import { PieChart } from 'react-minimal-pie-chart';

export const ViewReportPage = () => {
  const { reportId } = useParams();
  const { user } = useAuth0();
  const profile: ProfileStateInterface = useSelector(getProfile);
  const [reports, setReports] = useState<ReportInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const userRoles = get(user, ['https://api.emvest.ai/roles'], []);
  const isAdmin = userRoles.includes(ROLE_ADMIN);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const args: ReadReports = {
          sortBy: 'created',
          sortOrder: 'DESC',
          limit: 1,
        };
        const response = await emvestApi.get('/reports', args);
        const results = get(response, ['results'], []);
        setReports(results);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoading(false);
    };
    fetchReports();
  }, []);

  const gotoEditReport = () => {
    navigate(`/admin/reports/edit/${reportId}`);
  };

  if (!reportId) return null;

  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        {isAdmin && <Anchor href="/admin/reports">Reports</Anchor>}
        <Text color="dimmed">Report preview</Text>
      </Breadcrumbs>
      <Space h="xl" />
      <Group sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <Title>Report preview</Title> */}
        <Title>{profile.firstName}, Diversified Growth Rockstar!</Title>
        {isAdmin && (
          <Button variant="default" onClick={gotoEditReport}>
            Edit report
          </Button>
        )}
      </Group>
      <Text color="dimmed">Septermber 20th, 2022</Text>
      <Space h="md" />

      {/* <SimpleGrid cols={2}>
        {reports.map((report) => (
          <div key={report.id}>
            <ReportCard report={report} />
          </div>
        ))}
      </SimpleGrid> */}

      {loading && <Skeleton animate={true} height={274} width="100%" />}
      {!loading && !error && !reports.length && (
        <>
          <Space h="xl" />
          <Alert
            title="No reports found"
            color="yellow"
            variant="outline"
            icon={<FontAwesomeIcon icon={faSnooze} />}
          >
            Please check back later
          </Alert>
        </>
      )}
      {/* {error && (
        <>
          <Space h="xl" />
          <Alert
            title="Something went wrong"
            color="red"
            variant="outline"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          >
            Please check back later
          </Alert>
        </>
      )} */}

      <Space h="xl" />
      {!loading && (
        <>
          <Stack>
            <Grid grow columns={4} gutter="xl">
              <Grid.Col span={1}>
                <Card
                  p="lg"
                  radius="md"
                  sx={(theme) => ({
                    backgroundColor: theme.colors.emvestPurple[6],
                  })}
                >
                  <Title
                    order={3}
                    align="center"
                    color="dimmed"
                    sx={{ color: '#fff', marginTop: 10 }}
                  >
                    Portfolio Score
                  </Title>
                  <Text
                    sx={{ color: '#fff', fontSize: 80, fontWeight: 500 }}
                    align="center"
                  >
                    A
                  </Text>
                </Card>
              </Grid.Col>
              <Grid.Col span={3}>
                <Card p="lg" radius="md" withBorder>
                  <Stack>
                    <Text size="lg">
                      <b>
                        Your portfolio reflects your growth orientation.
                        Excellent job!
                      </b>
                    </Text>
                    <Text size="lg">
                      Recommendation: Given your portfolio mix, we encourage
                      moving out of low growth stocks in your portfolio to meet
                      your target return goals. Click below for stock
                      suggestions for industry diversification.
                    </Text>
                    <Group>
                      <Button
                        variant="outline"
                        onClick={() => console.log('CLICK')}
                      >
                        Get stock recommendations
                      </Button>
                    </Group>
                  </Stack>
                </Card>
              </Grid.Col>
            </Grid>

            <Space h="xs" />

            <Grid grow columns={8} gutter="xl">
              <Grid.Col span={5}>
                <Space h="lg" />
                <Space h="lg" />
                <Space h="lg" />
                <Space h="lg" />
                <Card p="lg" radius="md" withBorder>
                  <Stack>
                    <Text size="lg">
                      Out of your 17 investments, 11 stocks (65%) have a high
                      growth profile. Your portfolio is diversified across
                      industries, but not across <a href="#">sectors</a>. You
                      may want to reevaluate GPS, CLX, W, and TSN given its low
                      growth orientation due to company specific and market
                      challenges in 2022.
                    </Text>
                  </Stack>
                </Card>
              </Grid.Col>
              <Grid.Col span={3}>
                <PieChart
                  // label={({ dataEntry }) => dataEntry.title}
                  // labelStyle={{
                  //   fontSize: '5px',
                  //   fontWeight: 500,
                  //   color: '#ffffff',
                  // }}
                  startAngle={270}
                  data={[
                    { title: 'High Growth, 65%', value: 65, color: '#C476E5' },
                    {
                      title: 'Medium, 12%',
                      value: 12,
                      color: '#D49DEC',
                    },
                    { title: 'Low, 23%', value: 23, color: '#E5C3F4' },
                  ]}
                />
              </Grid.Col>
            </Grid>

            <Space h="xs" />

            <Card
              sx={{ maxWidth: 500, margin: '20px auto' }}
              p="lg"
              radius="md"
              withBorder
            >
              <Stack>
                <Center>
                  <FontAwesomeIcon
                    icon={faLightbulb}
                    style={{ width: 40, height: 40 }}
                  />
                </Center>
                <Text size="lg" align="center">
                  <b>Idea:</b> Have you considered the healthcare sector to
                  diversify your portfolio further? May be a worthwhile look to
                  round out your portfolio.
                </Text>
                <Group position="center">
                  <Button
                    variant="outline"
                    onClick={() => console.log('CLICK')}
                  >
                    Get stock recommendations
                  </Button>
                </Group>
              </Stack>
            </Card>
          </Stack>
        </>
      )}
    </Page>
  );
};
