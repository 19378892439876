import { SCHEMA_VERSION } from '@emvest/schema';
import { CLIENT_ID } from '../constants';
import { store } from '../store';
import { getAuthToken } from '../store/auth';
import get from 'lodash/get';

// setup api params
// const apiUrl = 'https://api.emvest.ai';
const apiUrl = 'https://pavm4j8wl0.execute-api.us-east-2.amazonaws.com/dev';
// const apiUrl = 'http://localhost:4000';

// setup base client
const headers = {
  Authorization: '',
  'Content-Type': 'application/json',
  'x-client-name': CLIENT_ID,
  'x-client-api-version': SCHEMA_VERSION,
};

// define types
export interface EmvestApiInterface {
  endpoint: string;
  data: any;
  logoutOnFail?: boolean;
}
type Method = 'POST' | 'GET' | 'PUT' | 'DELETE';

const emvestApi = {
  get: (endpoint: string, data: any, logoutOnFail: boolean = false) =>
    request('GET', { endpoint, data, logoutOnFail }),
  post: (endpoint: string, data: any, logoutOnFail: boolean = false) =>
    request('POST', { endpoint, data, logoutOnFail }),
  put: (endpoint: string, data: any, logoutOnFail: boolean = false) =>
    request('PUT', { endpoint, data, logoutOnFail }),
  delete: (endpoint: string, data: any, logoutOnFail: boolean = false) =>
    request('DELETE', { endpoint, data, logoutOnFail }),
};
export default emvestApi;

const request = (method: Method, args: EmvestApiInterface) => {
  // gather arguments
  const { endpoint, data, logoutOnFail } = args;
  const url = apiUrl + endpoint;

  // add accessKey, if available
  const globalState = store.getState();
  const accessToken = getAuthToken(globalState);
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  // return request promise
  return new Promise(async (resolve, reject) => {
    let fetchUrl = url;

    // build fetch request
    const fetchRequest = () => {
      switch (method) {
        case 'GET':
          fetchUrl += '?' + new URLSearchParams(data);
          return fetch(fetchUrl, {
            method,
            headers,
          });
        default:
          return fetch(fetchUrl, {
            method,
            headers,
            body: JSON.stringify(data),
          });
      }
    };

    // execute request
    try {
      const response = await fetchRequest();

      // detect response code
      const statusCode = get(response, ['status'], null);

      switch (statusCode) {
        case 200:
          // 200 SUCCESS - return response body
          const responseBody = await response.json();
          resolve(responseBody);
          break;
        case 412:
          // 412 PRECONDITION_FAILED - client api version is out-of-date
          if (logoutOnFail) {
            // force user logout (which will lead to Auth0 login, which leads back to fresh app load)
            window.location.href = '/logout';
          } else {
            // refresh the page
            window.location.reload();
          }
          reject({ code: statusCode, message: 'Client out-of-date' });
          break;
        case 403:
          // 403 FORBIDDEN
          // force user logout
          window.location.href = '/logout';
          reject({ code: statusCode, message: 'Login required' });
          break;
        default:
          // something went wrong
          if (logoutOnFail) {
            // force user logout
            window.location.href = '/logout';
          }
          reject({ code: statusCode, message: 'Something went wrong' });
          break;
      }
    } catch (error) {
      if (logoutOnFail) {
        // force user logout
        window.location.href = '/logout';
      }
      reject(error);
    }
  });
};

// EXAMPLE USAGE

// import emvestApi from '../../utils/emvestApi';
// const exampleApiCalls = async () => {
//   console.log('CALLING API');
//   try {
//     const response = await emvestApi.get('/users', { limit: 1 });
//     // const response = await emvestApi.post('/users', {
//     //   auth0Id: 'test16',
//     //   username: 'matthew+test16@emvest.ai',
//     //   email: 'matthew+test16@emvest.ai',
//     //   firstName: 'Matthew',
//     // });
//     // const response = await emvestApi.put('/users', {
//     //   id: '52200dc7-7c5e-4a80-b7ae-7663724c3b38',
//     //   firstName: 'UPDATED NAME',
//     // });
//     // const response = await emvestApi.delete('/users', {
//     //   id: '52200dc7-7c5e-4a80-b7ae-7663724c3b38',
//     // });
//     console.log('RESPONSE', response);
//   } catch (error) {
//     console.log('ERROR: ' + error);
//   }
// };
