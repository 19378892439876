import React from 'react';
import { createStyles, Group, Navbar, ScrollArea, Box } from '@mantine/core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faUsers,
  faComments,
  faFileChartColumn,
} from '@fortawesome/sharp-solid-svg-icons';
import { NavLink } from 'react-router-dom';

type AdminSidebarProps = {
  opened: boolean;
};

const useStyles = createStyles((theme) => ({
  links: {
    marginLeft: -theme.spacing.md,
    marginRight: -theme.spacing.md,
  },
  linksInner: {
    paddingBottom: 40,
  },
  control: {
    fontWeight: 500,
    display: 'block',
    width: '100%',
    padding: `16px 20px`,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },
  controlActive: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[9]
        : theme.colors.gray[1],
  },
  linkIcon: {
    borderRadius: 3,
    padding: 8,
    width: 30,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.gray[7]
        : theme.colors.gray[6],
  },
}));

const links = [
  { label: 'Dashboard', url: '/', icon: faHouse },
  { label: 'Users', url: '/admin/users', icon: faUsers },
  { label: 'Reports', url: '/admin/reports', icon: faFileChartColumn },
  { label: 'Feedback', url: '/admin/feedback', icon: faComments },
];

const AdminSidebar = ({ opened }: AdminSidebarProps) => {
  const { classes } = useStyles();

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      width={{ sm: 200, lg: 300 }}
    >
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>
          {links.map((link) => {
            return (
              <NavLink
                key={link.label}
                to={link.url}
                className={(navData) =>
                  navData.isActive
                    ? classes.control + ' ' + classes.controlActive
                    : classes.control
                }
              >
                <Group position="apart" spacing={0}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* <FontAwesomeIcon
                      className={classes.linkIcon}
                      icon={link.icon}
                      size="xl"
                    /> */}
                    <Box ml="md">{link.label}</Box>
                  </Box>
                </Group>
              </NavLink>
            );
          })}
        </div>
      </Navbar.Section>
    </Navbar>
  );
};

export default AdminSidebar;
