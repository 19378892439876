import React from 'react';
import {
  createStyles,
  Group,
  Avatar,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { UserInterface } from '@emvest/schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/sharp-solid-svg-icons';

type UserSummaryProps = {
  user: UserInterface;
  fullName?: boolean;
  large?: boolean;
  fullWidth?: boolean;
  onClick?: any;
};

const useStyles = createStyles((theme) => ({
  userButton: {
    display: 'inline-block',
    // width: '100%',
    padding: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.colors.gray[0],
    },
  },
  userButtonFullWidth: {
    width: '100%',
  },
  userButtonInnerFullWidth: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  userDisplay: {},
}));

export const UserSummary = ({
  user,
  fullName,
  large,
  fullWidth,
  onClick,
}: UserSummaryProps) => {
  const { classes } = useStyles();

  const buttonClasses = fullWidth
    ? classes.userButton + ' ' + classes.userButtonFullWidth
    : classes.userButton;

  // clickable button
  if (onClick)
    return (
      <UnstyledButton className={buttonClasses} onClick={onClick}>
        {fullWidth ? (
          <Group className={classes.userButtonInnerFullWidth}>
            <UserSummaryComponent
              user={user}
              fullName={fullName}
              large={large}
            />
            <FontAwesomeIcon style={{ opacity: 0.3 }} icon={faChevronRight} />
          </Group>
        ) : (
          <Group>
            <UserSummaryComponent
              user={user}
              fullName={fullName}
              large={large}
            />
            <FontAwesomeIcon style={{ opacity: 0.3 }} icon={faChevronRight} />
          </Group>
        )}
      </UnstyledButton>
    );

  // plain display
  return <UserSummaryComponent user={user} fullName={fullName} large={large} />;
};

const UserSummaryComponent = ({ user, fullName, large }: UserSummaryProps) => {
  const { classes } = useStyles();

  return (
    <Group className={classes.userDisplay}>
      <Avatar src={user.photo} size={large ? 80 : 38} />

      <div style={{ flex: 1 }}>
        <Text weight={500} size={large ? 'xl' : 'sm'}>
          {fullName ? user.firstName + ' ' + user.lastName : user.firstName}
        </Text>
        <Text color="dimmed" size={large ? 'md' : 'xs'}>
          {user.email}
        </Text>
      </div>
    </Group>
  );
};
