import React, { useEffect, useState } from 'react';
import Page from '../../components/page';
import {
  Space,
  Skeleton,
  Table,
  Text,
  Alert,
  Box,
  Title,
  Stack,
  Card,
  SimpleGrid,
  Breadcrumbs,
  Anchor,
  Group,
  Button,
} from '@mantine/core';
import { useParams, useNavigate } from 'react-router-dom';
import emvestApi from '../../utils/emvestApi';
import {
  UserInterface,
  ReadUsers,
  UserFormInterface,
  ReadUserForms,
  ReportInterface,
  ReadReports,
} from '@emvest/schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSnooze,
  faExclamationTriangle,
} from '@fortawesome/sharp-solid-svg-icons';
import format from 'date-fns/format';
import { UserSummary } from '../../components/userSummary';
import { promptMap } from './promptMap';
import { ReportCard } from '../../components/reportCard';
import get from 'lodash/get';

export const UserProfilePage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingUserForms, setLoadingUserForms] = useState(true);
  const [loadingReports, setLoadingReports] = useState(true);
  const [profile, setProfile] = useState<UserInterface | null>(null);
  const [userForms, setUserForms] = useState<UserFormInterface[]>([]);
  const [reports, setReports] = useState<ReportInterface[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const args: ReadUsers = {
          id: userId,
        };
        const response = await emvestApi.get('/users', args);
        const result = get(response, ['results', 0], null);
        setProfile(result);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoadingProfile(false);
    };
    fetchProfile();
  }, [userId]);

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const args: ReadUserForms = {
          userId: get(profile, ['id'], ''),
        };
        const response = await emvestApi.get('/userForms', args);
        const results = get(response, ['results'], []);
        condenseUserForms(results);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoadingUserForms(false);
    };
    const fetchReports = async () => {
      try {
        const args: ReadReports = {
          userId: get(profile, ['id'], ''),
          sortBy: 'created',
          sortOrder: 'DESC',
          limit: 100,
        };
        const response = await emvestApi.get('/reports', args);
        const results = get(response, ['results'], []);
        setReports(results);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoadingReports(false);
    };

    if (profile) {
      fetchForms();
      fetchReports();
    }
  }, [profile]);

  const condenseUserForms = (userForms: UserFormInterface[]) => {
    // only show the latest submission
    // (for onboarding forms)

    // forms that can have multiple submissions
    const multipleSubmissionForms = userForms.filter((submission) => {
      if (
        ['Onboarding Step Two', 'Onboarding Step Three'].includes(
          submission.form.label
        )
      )
        return false;
      return true;
    });

    // get only the latest of single submissions
    // assume that submissions are returned pre-sorted by submission date
    const onboardingStepTwoSubmissions = userForms.filter((submission) => {
      if (submission.form.label !== 'Onboarding Step Two') return false;
      return true;
    });
    const latestStepTwo = get(onboardingStepTwoSubmissions, [0], null);

    const onboardingStepThreeSubmissions = userForms.filter((submission) => {
      if (submission.form.label !== 'Onboarding Step Three') return false;
      return true;
    });
    const latestStepThree = get(onboardingStepThreeSubmissions, [0], null);

    // condense and return results
    const condensedSubmissions = multipleSubmissionForms;
    if (latestStepThree) {
      condensedSubmissions.push(latestStepThree);
    }
    if (latestStepTwo) {
      condensedSubmissions.push(latestStepTwo);
    }
    setUserForms(condensedSubmissions);
  };

  const gotoNewReport = () => {
    if (profile) {
      navigate(`/admin/reports/new/${profile.id}`);
    }
  };

  const profileReady = !loadingProfile && !error && profile;

  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        <Anchor href="/admin/users">Users</Anchor>
        <Text color="dimmed">User profile</Text>
      </Breadcrumbs>
      <Space h="xl" />
      {loadingProfile && (
        <>
          <Skeleton animate={true} height={80} width={300} />
          <Space h="xl" />
          <Skeleton animate={true} height={88} width={'100%'} />
        </>
      )}
      {profileReady && (
        <>
          <UserSummary user={profile} fullName={true} large={true} />
          <Space h="xl" />
          <Card p="lg" radius="md" withBorder>
            <Stack>
              <div>
                <Text weight={500}>Onboarding</Text>
                <Text size="sm" color="dimmed">
                  {profile.onboardingComplete
                    ? 'Complete'
                    : `Step ${profile.onboardingStep} / 3`}
                </Text>
              </div>
            </Stack>
          </Card>
        </>
      )}
      <Space h="xl" />

      {error && (
        <>
          <Space h="xl" />
          <Alert
            title="Something went wrong"
            color="red"
            variant="outline"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          >
            Please check back later
          </Alert>
          <Space h="xl" />
        </>
      )}

      {!loadingReports && (
        <>
          <Group sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title order={2}>Reports</Title>
            <Button variant="outline" onClick={gotoNewReport}>
              New report
            </Button>
          </Group>
          <Space h="xl" />
          {!reports.length && (
            <>
              <Alert
                title="No reports found"
                color="yellow"
                variant="outline"
                icon={<FontAwesomeIcon icon={faSnooze} />}
              >
                No reports have been written for this user yet.
              </Alert>
              <Space h="xl" />
            </>
          )}
          <SimpleGrid cols={2}>
            {reports.map((report) => (
              <div key={report.id}>
                <ReportCard report={report} />
              </div>
            ))}
          </SimpleGrid>
        </>
      )}

      <Space h="xl" />
      <Title order={2}>Submitted forms</Title>
      <Space h="xl" />
      {loadingUserForms && (
        <Skeleton animate={true} height={300} width="100%" />
      )}
      {!loadingUserForms && !error && !userForms.length && (
        <>
          <Space h="xl" />
          <Alert
            title="No forms found"
            color="yellow"
            variant="outline"
            icon={<FontAwesomeIcon icon={faSnooze} />}
          >
            This user hasn't submitted any forms yet.
          </Alert>
        </>
      )}
      {profile && (
        <SimpleGrid cols={1}>
          {userForms.map((userForm) => {
            const answerKeys = Object.keys(userForm.responseJSON);
            const submittedDate = format(
              new Date(userForm.updated),
              'MMMM do, yyyy'
            );

            return (
              <Card key={userForm.id} p="lg" radius="md" withBorder>
                <Stack>
                  <div>
                    <Text weight={500}>{userForm.form.label}</Text>
                    <Text size="sm" color="dimmed">
                      {submittedDate}
                    </Text>
                  </div>

                  <Card.Section withBorder>
                    <Box
                      sx={(theme) => ({
                        backgroundColor:
                          theme.colorScheme === 'dark'
                            ? theme.colors.dark[5]
                            : theme.colors.gray[0],
                      })}
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>Prompt</th>
                            <th>Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {answerKeys.map((key: string) => {
                            const rawValue: unknown =
                              userForm.responseJSON[key];
                            let value: string = '';

                            switch (typeof rawValue) {
                              case 'string':
                              case 'number':
                                value = String(rawValue);
                                if (key === 'portfolioSize') {
                                  value = new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                  }).format(Number(rawValue));
                                }
                                break;
                              case 'boolean':
                                value = rawValue ? 'True' : 'False';
                                break;
                              case 'object':
                                if (Array.isArray(rawValue)) {
                                  value = rawValue.join('\n');
                                }
                                break;
                              default:
                                break;
                            }

                            return (
                              <tr key={key}>
                                <td>{promptMap[key]}</td>
                                <td style={{ whiteSpace: 'pre-wrap' }}>
                                  {value}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Box>
                  </Card.Section>

                  <Text size="sm" color="dimmed" align="right">
                    {profile.firstName +
                      ' submitted this form on ' +
                      submittedDate}
                  </Text>
                </Stack>
              </Card>
            );
          })}
        </SimpleGrid>
      )}
    </Page>
  );
};
