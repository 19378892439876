type brokerOption = {
  value: string;
  label: string;
  group?: string;
};

const brokers: brokerOption[] = [
  {
    value: 'None',
    label: "I don't have a broker yet",
    group: 'None',
  },
  { value: 'Charles Schwab', label: 'Charles Schwab', group: 'US Brokers' },
  { value: 'Fidelity', label: 'Fidelity', group: 'US Brokers' },
  { value: 'Vanguard', label: 'Vanguard', group: 'US Brokers' },
  { value: 'Acorns', label: 'Acorns', group: 'US Brokers' },
  { value: 'Wealthfront', label: 'Wealthfront', group: 'US Brokers' },
  { value: 'Stash', label: 'Stash', group: 'US Brokers' },
  { value: 'Schwab', label: 'Schwab', group: 'US Brokers' },
  { value: 'TD Ameritrade', label: 'TD Ameritrade', group: 'US Brokers' },
  { value: 'etrade', label: 'etrade', group: 'US Brokers' },
  { value: 'Robinhood', label: 'Robinhood', group: 'US Brokers' },
  { value: 'Well Fargo', label: 'Well Fargo', group: 'US Brokers' },
  { value: 'Marcus', label: 'Marcus', group: 'US Brokers' },
  {
    value: 'Other',
    label: 'Other/not listed',
    group: 'Other',
  },
];

export default brokers;
