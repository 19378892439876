import React from 'react';
import { SimpleGrid, Checkbox, Radio, Input } from '@mantine/core';
import remove from 'lodash/remove';
import { ImageCheckbox } from './imageCheckbox';

export type CheckboxGroupProps = {
  options: CheckboxOptions[];
  selections: string[];
  label: string;
  description?: string;
  error?: any;
  required?: boolean;
  min?: number;
  max?: number;
  onChange: any;
};
export type CheckboxOptions = {
  style?: 'default' | 'image' | 'icon';
  label: string;
  value: string;
  description?: string;
  icon?: string;
  image?: string;
};

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const {
    options,
    selections = [],
    label,
    description,
    error,
    required = false,
    min,
    max,
    onChange,
  } = props;

  const isRadio = min === 1 && max === 1;

  const toggleCheckbox = (value: string) => {
    if (selections.includes(value)) {
      // remove the selection
      if (!isRadio || selections.length > min) {
        const newSelections = [...selections];
        remove(newSelections, (o) => o === value);
        onChange([...newSelections]);
      }
    } else {
      // add the selection
      const newSelections = [...selections];
      if (max && selections.length >= max) {
        // if over the max, remove the first in stack
        newSelections.shift();
      }
      onChange([...newSelections, value]);
    }
  };

  return (
    <>
      <Input.Wrapper
        label={label}
        description={description}
        error={error}
        withAsterisk={required}
      >
        <SimpleGrid
          cols={2}
          breakpoints={[{ maxWidth: 800, cols: 1 }]}
          style={{ width: '100%', margin: '8px 0' }}
        >
          {options.map((option: CheckboxOptions) => {
            const { style, label, value, description, icon, image } = option;

            switch (style) {
              case 'icon':
                return (
                  <ImageCheckbox
                    key={value}
                    value={value}
                    checked={selections.includes(value)}
                    title={label}
                    description={description}
                    icon={icon}
                    image={image}
                    handleChange={() => toggleCheckbox(value)}
                    isRadio={isRadio}
                  />
                );
              default:
                if (isRadio)
                  return (
                    <Radio
                      key={value}
                      value={value}
                      checked={selections.includes(value)}
                      onChange={() => toggleCheckbox(value)}
                      label={label}
                    />
                  );
                return (
                  <Checkbox
                    key={value}
                    checked={selections.includes(value)}
                    onChange={() => toggleCheckbox(value)}
                    label={label}
                  />
                );
            }
          })}
        </SimpleGrid>
      </Input.Wrapper>
    </>
  );
};

export default CheckboxGroup;
