import React, { useState, useEffect } from 'react';
import { Space, Title, Skeleton, Alert } from '@mantine/core';
import { saveForm } from '../../../store/forms';
import emvestApi from '../../../utils/emvestApi';
import { ReadFormId, ReadFormSubmission } from '@emvest/schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/sharp-solid-svg-icons';
import { useAppDispatch } from '../../../store/hooks';
import { StepTwoForm } from './form';
import get from 'lodash/get';

export const OnboardingStepTwo = () => {
  const formLabel = 'Onboarding Step Two';
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(false);
  const [formId, setFormId] = useState(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // fetch saved form data from database
    const fetchSavedForm = async () => {
      try {
        const lookupArgs: ReadFormId = { label: formLabel };
        const response = await emvestApi.get('/forms/id', lookupArgs);
        const theFormId = get(response, ['formId'], null);
        if (!theFormId) {
          throw new Error('Something went wrong');
        }
        setFormId(theFormId);

        const readArgs: ReadFormSubmission = { formId: theFormId };
        const savedFormResponse = await emvestApi.get(
          '/forms/submission',
          readArgs
        );
        dispatch(saveForm({ formId: theFormId, data: savedFormResponse }));
        setReady(true);
      } catch (e) {
        console.error(e);
        setError(true);
      }
    };
    fetchSavedForm();
  }, [dispatch]);

  // Step 2: life goals
  return (
    <>
      <Title order={2}>Life goals</Title>
      <Space h="md" />

      {error ? (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
            title="Something went wrong"
            color="red"
          >
            Sorry for the inconvenience - please try again or reach out to our
            support team at support@emvest.ai.
          </Alert>
          <Space h="md" />
        </>
      ) : (
        <>
          {ready && formId ? (
            <StepTwoForm formId={formId} />
          ) : (
            <Skeleton animate={true} height={300} width="100%" />
          )}
        </>
      )}
    </>
  );
};
