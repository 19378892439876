import { useEffect } from 'react';
import { AUTH0_AUDIENCE } from '../constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../store/hooks';
import { setToken } from '../store/auth';
import resetRedux from '../store/reset';
import { getAuthToken } from '../store/auth';
import { store } from '../store';

const useAuthWatcher = () => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently, isLoading, isAuthenticated, user } =
    useAuth0();

  useEffect(() => {
    // triggered on any auth0 state changes
    const getAccessToken = async () => {
      if (isLoading) return null;
      let accessToken = null;
      if (isAuthenticated) {
        accessToken = await getAccessTokenSilently({ AUTH0_AUDIENCE });
      }

      // get prev token
      const globalState = store.getState();
      const prevAccessToken = getAuthToken(globalState);

      // save token to recoil for easy access
      dispatch(setToken(accessToken));

      // if no token, erase recoil atoms
      if (!accessToken) {
        resetRedux(dispatch);
      }

      // if user frehly redirected from login, execute the redirect
      if (isAuthenticated && prevAccessToken !== accessToken) {
        window.location.reload();
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently, isLoading, isAuthenticated, user, dispatch]);

  return {};
};

export default useAuthWatcher;
