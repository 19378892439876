import React, { useEffect, useState } from 'react';
import Page from '../../components/page';
import { Title, SimpleGrid, Space, Skeleton, Alert } from '@mantine/core';
import emvestApi from '../../utils/emvestApi';
import { useSelector } from 'react-redux';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import { ProgressCard } from '../../components/progressCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUsers,
  faWagonCovered,
  faComments,
  faFileChartColumn,
  faExclamationTriangle,
} from '@fortawesome/sharp-solid-svg-icons';
import { ReadUsers, ReadFeedbacks, ReadReports } from '@emvest/schema';
import get from 'lodash/get';

export const AdminDashboard = () => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const [loading, setLoading] = useState(true);
  const [registeredUserCount, setRegisteredUserCount] = useState(0);
  const [onboardedUserCount, setOnboardedUserCount] = useState(0);
  const [feedbackCount, setFeedbackCount] = useState(0);
  const [reportCount, setReportCount] = useState(0);
  const [completedReportCount, setCompletedReportCount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const args: ReadUsers = {
          limit: 1,
        };
        const response = await emvestApi.get('/users', args);
        const count = get(response, ['total'], []);
        setRegisteredUserCount(count);

        const onboardedArgs: ReadUsers = {
          limit: 1,
          onboardingComplete: true,
        };
        const onboardedResponse = await emvestApi.get('/users', onboardedArgs);
        const onboardedCount = get(onboardedResponse, ['total'], []);
        setOnboardedUserCount(onboardedCount);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoading(false);
    };
    const fetchFeedback = async () => {
      try {
        const args: ReadFeedbacks = {
          sortBy: 'created',
          sortOrder: 'DESC',
          limit: 1,
        };
        const response = await emvestApi.get('/feedback', args);
        const count = get(response, ['total'], []);
        setFeedbackCount(count);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoading(false);
    };
    const fetchReports = async () => {
      try {
        const allArgs: ReadReports = {
          limit: 1,
        };
        const allResponse = await emvestApi.get('/reports', allArgs);
        const allCount = get(allResponse, ['total'], 0);
        setReportCount(allCount);

        const completedArgs: ReadReports = {
          limit: 1,
          status: 'published',
        };
        const completedResponse = await emvestApi.get(
          '/reports',
          completedArgs
        );
        const completedCount = get(completedResponse, ['total'], 0);
        setCompletedReportCount(completedCount);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoading(false);
    };

    fetchStats();
    fetchFeedback();
    fetchReports();
  }, []);

  // dashboard
  return (
    <Page>
      <Title>Hi {profile.firstName}!</Title>
      <Space h="xl" />
      {loading && <Skeleton animate={true} height={300} width="100%" />}
      {!loading && !error && (
        <SimpleGrid cols={2} spacing="xl">
          <ProgressCard
            title={'Registered users'}
            icon={faUsers}
            goal={'Goal: 100 users by November'}
            count={registeredUserCount}
            goalCount={100}
            goalLabel={'users'}
            buttonText={'View users'}
            buttonUrl={'/admin/users'}
          />
          <ProgressCard
            title={'Onboarded users'}
            icon={faWagonCovered}
            goal={'Goal: 90% onboarded'}
            count={onboardedUserCount}
            goalCount={90}
            goalLabel={'onboarded'}
            buttonText={'View users'}
            buttonUrl={'/admin/users'}
          />
          <ProgressCard
            title={'Completed reports'}
            icon={faFileChartColumn}
            goal={'Goal: 100% draft completion'}
            count={completedReportCount}
            goalCount={reportCount}
            goalLabel={'reports'}
            buttonText={'View reports'}
            buttonUrl={'/admin/reports'}
          />
          <ProgressCard
            title={'Feedback messages'}
            icon={faComments}
            goal={'Goal: 10 feedback messages'}
            count={feedbackCount}
            goalCount={10}
            goalLabel={'messages'}
            buttonText={'View feedback'}
            buttonUrl={'/admin/feedback'}
          />
        </SimpleGrid>
      )}

      {error && (
        <>
          <Space h="xl" />
          <Alert
            title="Something went wrong"
            color="red"
            variant="outline"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          >
            Please check back later
          </Alert>
        </>
      )}
    </Page>
  );
};
