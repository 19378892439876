type investmentTypeOption = {
  value: string;
  label: string;
  group?: string;
};

const investmentTypes: investmentTypeOption[] = [
  {
    value: 'None',
    label: "I don't have an investment account yet",
    group: 'None',
  },

  {
    value: 'brokerage',
    label: 'Brokerage',
    group: 'Personal trading account',
  },

  { value: '401k', label: '401K', group: 'Retirement' },
  { value: 'roth', label: 'Roth 401K', group: 'Retirement' },
  { value: 'ira', label: 'IRA', group: 'Retirement' },
  { value: 'roth ira', label: 'Roth IRA', group: 'Retirement' },

  { value: 'crypto', label: 'Crypto', group: 'Crypto' },
  { value: 'nft', label: "NFT's", group: 'Crypto' },

  { value: 'cd savings', label: "CD's", group: 'Savings' },
  {
    value: 'traditional savings',
    label: 'Traditional savings account',
    group: 'Savings',
  },
  {
    value: 'high yield savings',
    label: 'High-yield savings account',
    group: 'Savings',
  },
  {
    value: 'money market account',
    label: 'Money market account',
    group: 'Savings',
  },
];

export default investmentTypes;
