export const questions = [
  {
    id: 'a1',
    prompt: '1. A stock is ___________',
    options: [
      { value: 'a', label: "A type of soup that's been simmered" },
      { value: 'b', label: 'A share of ownership in a company' },
      { value: 'c', label: 'An investment in the government' },
      { value: 'd', label: 'A type of memo' },
    ],
  },
  {
    id: 'a2',
    prompt: '2. You should have diversified investments.',
    options: [
      { value: 'a', label: 'True' },
      { value: 'b', label: 'False' },
    ],
  },
  {
    id: 'a3',
    prompt: '3. A stock is also known as a ___________',
    options: [
      { value: 'a', label: 'Bond' },
      { value: 'b', label: 'ETF' },
      { value: 'c', label: 'Mutual Fund' },
      { value: 'd', label: 'Equity' },
    ],
  },
  {
    id: 'a4',
    prompt:
      '4. Growth stocks typically do not appreciate faster than the market average.',
    options: [
      { value: 'a', label: 'True' },
      { value: 'b', label: 'False' },
    ],
  },
  {
    id: 'a5',
    prompt: '5. A bond is ___________',
    options: [
      {
        value: 'a',
        label:
          'A type of loan, similar to an IOU, issued by a government, municipality, or corporation',
      },
      { value: 'b', label: 'A perfume brand' },
      { value: 'c', label: 'A type of glue' },
      {
        value: 'd',
        label: 'A debt security that is generally riskier than stocks',
      },
    ],
  },
  {
    id: 'a6',
    prompt: "6. ETF's (exchange traded funds) are ___________",
    options: [
      {
        value: 'a',
        label: 'A pool of stocks, bonds, and other assets',
      },
      { value: 'b', label: 'A type of loan' },
      { value: 'c', label: 'A type of checking account' },
      {
        value: 'd',
        label: 'A bundle of tech startups',
      },
    ],
  },
  {
    id: 'a7',
    prompt:
      "7. Whether an ETF or mutual fund is actively or passively managed impacts the funds' fees.",
    options: [
      { value: 'a', label: 'True' },
      { value: 'b', label: 'False' },
    ],
  },
  {
    id: 'a8',
    prompt:
      '8. A stock market index is a collection of publicly traded company data, generally constructed by market capitalization (Shares outstanding x price per share). Stock market indices are also a performance indicator of different countries, industries, and regions. Which one of the following is not a stock market index?',
    options: [
      { value: 'a', label: 'Dow Jones Industrial Average' },
      { value: 'b', label: 'S&P 500' },
      { value: 'c', label: 'Russell 2000' },
      { value: 'd', label: 'Foot See 500' },
    ],
  },
  {
    id: 'a9',
    prompt:
      '9. Mutual funds are often actively managed, which means there is a team to select investments for a specific strategy.  On average, mutual funds fees are:',
    options: [
      { value: 'a', label: 'There are fees?!?!' },
      { value: 'b', label: 'Less than ETF fees' },
      { value: 'c', label: 'More than ETF fees' },
      { value: 'd', label: 'Equal to ETF fees' },
    ],
  },
  {
    id: 'a10',
    prompt:
      '10. Dividends are payments when the company distributes some of its earnings to stockholders. Startup tech companies usually pay dividends.',
    options: [
      { value: 'a', label: 'True' },
      { value: 'b', label: 'False' },
    ],
  },
];
