import { createStyles, Slider, Input } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = createStyles((theme) => ({
  sliderContainer: {
    margin: '10px 0 50px',
  },
  thumb: {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[3]
    }`,
    width: 28,
    height: 22,
    color: theme.colors.gray[5],
    backgroundColor: theme.white,
    borderRadius: theme.radius.sm,
  },
}));

type SliderInputProps = {
  label: string;
  value: number;
  onChange: any;
  description?: string;
  error?: any;
  required?: boolean;
  min: number;
  max: number;
  marks: SliderMark[];
  interval: number;
};
export type SliderMark = {
  value: number;
  label: any;
};

export const SliderInput = (props: SliderInputProps) => {
  const {
    label,
    value,
    onChange,
    description,
    error,
    required = false,
    min,
    max,
    marks,
    interval,
  } = props;
  const { classes } = useStyles();

  return (
    <Input.Wrapper
      label={label}
      description={description}
      error={error}
      withAsterisk={required}
    >
      <div className={classes.sliderContainer}>
        <Slider
          min={min}
          max={max}
          value={value}
          onChange={onChange}
          classNames={classes}
          label={null}
          marks={marks}
          step={interval}
          thumbChildren={<FontAwesomeIcon icon={['fass', 'grip-dots']} />}
        />
      </div>
    </Input.Wrapper>
  );
};
