// schema version
export const SCHEMA_VERSION = '0.0.1';

// user role constants
export const ROLE_ADMIN = 'admin';
export const ROLE_USER = 'user';
export const ROLE_GUEST = 'guest';

// special issued API access roles
export const ROLE_DEV = 'dev';
export const ROLE_AUTH0 = 'auth0';
