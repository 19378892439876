import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { GenericLoadingScreen } from '../../components/genericLoadingScreen';

export const LogoutPage = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    setTimeout(() => {
      logout();
    }, 2000);
  }, [logout]);

  return <GenericLoadingScreen />;
};
