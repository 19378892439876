import React, { useState } from 'react';
import {
  Alert,
  Stack,
  Space,
  MultiSelect,
  Button,
  TextInput,
  Group,
  Select,
} from '@mantine/core';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProfileStateInterface, getProfile } from '../../../store/profile';
import { FormInterface, getSavedForm } from '../../../store/forms';
import { saveForm } from '../../../store/forms';
import { useSelector } from 'react-redux';
import CheckboxGroup from '../../../components/form/checkboxGroup';
import { SliderInput } from '../../../components/form/sliderInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/sharp-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emvestApi from '../../../utils/emvestApi';
import { UserInterface, SubmitForm, UpdateUser } from '@emvest/schema';
import { useAppDispatch } from '../../../store/hooks';
import { setProfile, initialProfileState } from '../../../store/profile';
import { resetOnboarding } from '../../../store/onboarding';
import financialGoalOptions from './financialGoals';
import investmentTypeOptions from './investmentTypes';
import brokerOptions from './brokers';
import get from 'lodash/get';

interface IFormInput {
  ageBracket: string;
  selectedInvestments: string[];
  selectedBrokers: string[];
  selectedGoals: string[];
  closenessRating: number;
  closenessDetails: string;
}
type FormProps = {
  formId: string;
};

export const StepTwoForm = ({ formId }: FormProps) => {
  const savedForm: FormInterface | null = useSelector(getSavedForm(formId));
  const profile: ProfileStateInterface = useSelector(getProfile);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [investmentTypes] = useState(investmentTypeOptions);

  const [brokers, setBrokers] = useState(brokerOptions);

  const schema = yup
    .object({
      ageBracket: yup.string().required('Age bracket is required'),
      selectedInvestments: yup
        .array()
        .min(1, 'Please select at least one')
        .required('Please select at least one'),
      selectedBrokers: yup
        .array()
        .min(1, 'Please select at least one')
        .required('Please select at least one'),
      selectedGoals: yup
        .array()
        .min(1, 'Please select at least one')
        .required('Please select at least one'),
      closenessRating: yup.number(),
      closenessDetails: yup.string(),
    })
    .required();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const savedFormData = get(savedForm, ['responseJSON'], null);
  const defaults: IFormInput = {
    ageBracket: get(savedFormData, ['ageBracket'], '18-30'),
    selectedInvestments: get(savedFormData, ['selectedInvestments'], []),
    selectedBrokers: get(savedFormData, ['selectedBrokers'], []),
    selectedGoals: get(savedFormData, ['selectedGoals'], []),
    closenessRating: get(savedFormData, ['closenessRating'], 3),
    closenessDetails: get(savedFormData, ['closenessDetails'], ''),
  };

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    defaultValues: defaults,
    resolver: yupResolver(schema),
  });

  const ageBracket = watch('ageBracket');
  const selectedInvestments = watch('selectedInvestments');
  const selectedBrokers = watch('selectedBrokers');
  const selectedGoals = watch('selectedGoals');
  const closenessRating = watch('closenessRating');
  const closenessDetails = watch('closenessDetails');

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);

    try {
      // submit form data to api
      const submitArgs: SubmitForm = {
        formId,
        data,
      };
      const response = await emvestApi.post('/forms/submit', submitArgs);

      // save updated data to redux
      if (response) {
        const savedFormArgs = {
          formId,
          data: response,
        };
        dispatch(saveForm(savedFormArgs));
      }

      // send updated user data to API
      const updateArgs: UpdateUser = {
        id: profile.id,
        onboardingStep: Math.max(3, profile.onboardingStep),
      };
      const userResponse = await emvestApi.put('/users', updateArgs);
      if (typeof userResponse === 'object') {
        // save updated data to redux
        const updatedUser: UserInterface = {
          ...initialProfileState,
          ...userResponse,
        };
        dispatch(setProfile(updatedUser));

        // update onboarding data
        dispatch(resetOnboarding());

        // proceed to next step
        navigate('/onboarding/3');
      } else {
        throw new Error('Response is not an object');
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  // Step 2: life goals
  return (
    <>
      {error && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
            title="Something went wrong"
            color="red"
          >
            Sorry for the inconvenience - please try again or reach out to our
            support team at support@emvest.ai.
          </Alert>
          <Space h="md" />
        </>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Select
            label="Age bracket"
            data={[
              { value: '18 or younger', label: '18 or younger' },
              { value: '18-30', label: '18-30' },
              { value: '30-40', label: '30-40' },
              { value: '40-50', label: '40-50' },
              { value: '50+', label: '50+' },
            ]}
            value={ageBracket}
            onChange={(selection) => setValue('ageBracket', selection || '18')}
            disabled={loading}
            error={errors.ageBracket?.message}
          />

          <MultiSelect
            label="What types of investment accounts do you have?"
            data={investmentTypes}
            placeholder="Choose accounts"
            description="Select all that apply"
            searchable
            withAsterisk
            value={selectedInvestments}
            onChange={(selections) =>
              setValue('selectedInvestments', selections)
            }
            disabled={loading}
            error={errors.selectedInvestments?.message}
          />

          <MultiSelect
            label="Where are your investment accounts?"
            data={brokers}
            placeholder="Choose brokers"
            description="Select all that apply"
            withAsterisk
            searchable
            // creatable
            getCreateLabel={(query) => `+ add ${query}`}
            onCreate={(query) => {
              const item = { value: query, label: query, group: 'Custom' };
              setBrokers((current) => [...current, item]);
              return item;
            }}
            disabled={loading}
            value={selectedBrokers}
            onChange={(selections) => setValue('selectedBrokers', selections)}
            error={errors.selectedBrokers?.message}
          />

          <CheckboxGroup
            label={'What are your financial goals for the next 3 years?'}
            description={'Select all that apply'}
            error={errors.selectedGoals?.message}
            required={true}
            options={financialGoalOptions}
            selections={selectedGoals}
            onChange={(selections: string[]) =>
              setValue('selectedGoals', selections)
            }
          />

          <SliderInput
            label={'How close do you feel to reaching these goals?'}
            // description={'Here'}
            required={false}
            value={closenessRating}
            onChange={(newRating: number) =>
              setValue('closenessRating', newRating)
            }
            min={1}
            max={5}
            interval={1}
            marks={[
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
              { value: 5, label: '5' },
            ]}
            error={errors.closenessRating?.message}
          />

          <TextInput
            label="Context for your goal closeness"
            placeholder="My investments are doing well, but I don't have enough saved..."
            defaultValue={closenessDetails}
            disabled={loading}
            error={errors.closenessDetails?.message}
            {...register('closenessDetails')}
          />

          <Group position="right" mt="md">
            <Button
              variant="default"
              loading={loading}
              onClick={() => navigate('/onboarding/1')}
            >
              Back
            </Button>
            <Button type="submit" loading={loading}>
              Next step
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};
