import React, { useState } from 'react';
import {
  Alert,
  Stack,
  Space,
  MultiSelect,
  Button,
  NumberInput,
  Group,
} from '@mantine/core';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProfileStateInterface, getProfile } from '../../../store/profile';
import { FormInterface, getSavedForm } from '../../../store/forms';
import { saveForm } from '../../../store/forms';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
  faCircleNotch,
  faChartSimple,
} from '@fortawesome/sharp-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emvestApi from '../../../utils/emvestApi';
import {
  UserInterface,
  SubmitForm,
  UpdateUser,
  ReadTickers,
  TickerInterface,
} from '@emvest/schema';
import { useAppDispatch } from '../../../store/hooks';
import { setProfile, initialProfileState } from '../../../store/profile';
import { setCelebration } from '../../../store/celebration';
import get from 'lodash/get';

interface IFormInput {
  portfolioSize: number;
  selectedTickers: string[];
}
type FormProps = {
  formId: string;
};
type TickerOption = {
  value: string;
  label: string;
  group?: string;
};

export const StepThreeForm = ({ formId }: FormProps) => {
  const savedForm: FormInterface | null = useSelector(getSavedForm(formId));
  const profile: ProfileStateInterface = useSelector(getProfile);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tickers, setTickers] = useState<TickerOption[]>([]);
  const [loadingTickers, setLoadingTickers] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const schema = yup
    .object({
      portfolioSize: yup
        .number()
        .min(0)
        .max(100000000000)
        .required('Estimated portfolio size is required'),
      selectedTickers: yup.array(),
    })
    .required();

  const savedFormData = get(savedForm, ['responseJSON'], null);
  const defaults: IFormInput = {
    portfolioSize: get(savedFormData, ['portfolioSize'], undefined),
    selectedTickers: get(savedFormData, ['selectedTickers'], []),
  };

  const {
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    defaultValues: defaults,
    resolver: yupResolver(schema),
  });

  const portfolioSize = watch('portfolioSize');
  const selectedTickers = watch('selectedTickers');

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);

    try {
      // submit form data to api
      const submitArgs: SubmitForm = {
        formId,
        data,
      };
      const response = await emvestApi.post('/forms/submit', submitArgs);

      // save updated data to redux
      if (response) {
        const savedFormArgs = {
          formId,
          data: response,
        };
        dispatch(saveForm(savedFormArgs));
      }

      // send updated user data to API
      const updateArgs: UpdateUser = {
        id: profile.id,
        onboardingComplete: true,
      };
      const userResponse = await emvestApi.put('/users', updateArgs);
      if (typeof userResponse === 'object') {
        // save updated data to redux
        const updatedUser: UserInterface = {
          ...initialProfileState,
          ...userResponse,
        };
        dispatch(setProfile(updatedUser));

        // update onboarding data
        dispatch(
          setCelebration({
            open: true,
            title: `Great work ${profile.firstName}! 😊`,
            message:
              // 'You\'re all set to go. Welcome to emvest!\n\nWe\'ll get back to you in the next couple days with your personal portfolio analysis.\n\nIn the meantime, feel free to explore your personal emvest.ai dashboard and take our "introduction to investing" quiz!',
              "You're all set to go. Welcome to emvest!\n\nWe'll get back to you in the next couple days with your personal portfolio analysis.",
            buttonText: 'My dashboard',
          })
        );

        // proceed to next step
        navigate('/', { replace: true });
      } else {
        throw new Error('Response is not an object');
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  const fetchTickers = async (query: string) => {
    if (query.length) {
      setLoadingTickers(true);
      const searchArgs: ReadTickers = {
        search: query,
        sortBy: 'symbol',
        sortOrder: 'ASC',
        limit: 20,
      };
      const searchResponse = await emvestApi.get('/tickers', searchArgs);
      // setLoading(true);
      if (typeof searchResponse === 'object') {
        const results = get(searchResponse, ['results'], []);
        const mappedResults = results.map((result: TickerInterface) => ({
          value: result.symbol + ': ' + result.name,
          label: result.symbol + ': ' + result.name,
          group: result.type,
        }));
        const mappedSelections = selectedTickers.map((ticker: string) => ({
          value: ticker,
          label: ticker,
        }));
        setTickers(mappedResults.concat(mappedSelections));
        setLoadingTickers(false);
      }
    }
  };

  // Step 2: life goals
  return (
    <>
      {error && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
            title="Something went wrong"
            color="red"
          >
            Sorry for the inconvenience - please try again or reach out to our
            support team at support@emvest.ai.
          </Alert>
          <Space h="md" />
        </>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <NumberInput
            label="Estimated dollars invested"
            description="This helps us provide you with accurate portfolio feedback"
            value={portfolioSize}
            withAsterisk
            onChange={(val: number) => setValue('portfolioSize', val)}
            parser={(val) => (val || '').replace(/\$\s?|(,*)/g, '')}
            formatter={(val) =>
              !Number.isNaN(parseFloat(val || ''))
                ? `$ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : '$ '
            }
            min={0}
            max={100000000000}
            step={1000}
            disabled={loading}
            error={errors.portfolioSize?.message}
          />

          <MultiSelect
            label="Share your investments for us to analyze"
            data={tickers}
            placeholder="Start typing to search"
            description="Type to search"
            searchable
            onSearchChange={fetchTickers}
            value={selectedTickers}
            onChange={(selections) => setValue('selectedTickers', selections)}
            error={errors.selectedTickers?.message}
            disabled={loading}
            icon={
              loadingTickers ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                <FontAwesomeIcon icon={faChartSimple} />
              )
            }
          />

          <Group position="right" mt="md">
            <Button
              variant="default"
              loading={loading}
              onClick={() => navigate('/onboarding/2')}
            >
              Back
            </Button>
            <Button type="submit" loading={loading}>
              Save portfolio
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};
