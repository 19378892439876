import React, { useEffect, useState } from 'react';
import Page from '../../components/page';
import {
  Title,
  Space,
  Skeleton,
  Table,
  Alert,
  Breadcrumbs,
  Anchor,
  Text,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import emvestApi from '../../utils/emvestApi';
import { UserInterface, ReadUsers } from '@emvest/schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faExclamationTriangle,
} from '@fortawesome/sharp-solid-svg-icons';
import { UserSummary } from '../../components/userSummary';
import format from 'date-fns/format';
import get from 'lodash/get';

export const UsersPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const args: ReadUsers = {
          limit: 200,
        };
        const response = await emvestApi.get('/users', args);
        const results = get(response, ['results'], []);
        setUsers(results);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoading(false);
    };
    fetchStats();
  }, []);

  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        <Text color="dimmed">Users</Text>
      </Breadcrumbs>
      <Space h="xl" />
      <Title>Users</Title>
      <Space h="xl" />
      {loading && <Skeleton animate={true} height={300} width="100%" />}
      {!loading && !error && (
        <Table verticalSpacing="md" highlightOnHover>
          <thead>
            <tr>
              <th>User</th>
              <th>Registered</th>
              <th>Onboarded</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              const onboardStatus = user.onboardingComplete ? (
                <FontAwesomeIcon icon={faCircleCheck} size="xl" />
              ) : (
                'step ' + user.onboardingStep + ' / 3'
              );
              const registrationDate = new Date(user.created);

              return (
                <tr
                  key={user.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/admin/users/' + user.id)}
                >
                  <td>
                    <UserSummary user={user} fullName={true} />
                  </td>
                  <td>{format(registrationDate, 'M/d/yyyy')}</td>
                  <td>{onboardStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}

      {error && (
        <>
          <Space h="xl" />
          <Alert
            title="Something went wrong"
            color="red"
            variant="outline"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          >
            Please check back later
          </Alert>
        </>
      )}
    </Page>
  );
};
