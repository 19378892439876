import React from 'react';
import {
  Group,
  Card,
  Space,
  Stack,
  Box,
  Table,
  Badge,
  Button,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ReportInterface } from '@emvest/schema';
import { UserSummary } from '../userSummary';
import format from 'date-fns/format';

type ReportCardProps = {
  report: ReportInterface;
};
export const ReportCard = ({ report }: ReportCardProps) => {
  const navigate = useNavigate();
  const reportDate = new Date(report.updated);
  const user = report.user;

  const gotoUserProfile = (userId: string) => {
    navigate(`/admin/users/${userId}`);
  };
  const gotoEditReport = (reportId: string) => {
    navigate(`/admin/reports/edit/${reportId}`);
  };
  const gotoReport = (reportId: string) => {
    navigate(`/reports/${reportId}`);
  };

  return (
    <Card p="lg" radius="md" withBorder>
      <Card.Section>
        <UserSummary
          user={user}
          fullName={true}
          onClick={() => gotoUserProfile(user.id)}
          fullWidth={true}
        />
      </Card.Section>
      <Space h="md" />
      <Stack>
        <Card.Section withBorder>
          <Box
            sx={(theme) => ({
              backgroundColor:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[5]
                  : theme.colors.gray[0],
            })}
          >
            <Table>
              <tbody>
                <tr>
                  <td>
                    <b>Report status</b>
                  </td>
                  <td>
                    <Badge
                      color={report.status === 'draft' ? 'orange' : 'green'}
                    >
                      {report.status}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Report type</b>
                  </td>
                  <td>
                    {report.type === 'analysis'
                      ? 'Portfolio analysis'
                      : 'Stock suggestions'}
                  </td>
                </tr>
                {report.status === 'published' && (
                  <tr>
                    <td>
                      <b>Publish date</b>
                    </td>
                    <td>{format(reportDate, 'MMMM do, yyyy')}</td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>Author</b>
                  </td>
                  <td>
                    {report.author
                      ? String(
                          report.author.firstName + ' ' + report.author.lastName
                        )
                      : 'unknown'}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Box>
        </Card.Section>
        <Group position="right">
          <Button variant="subtle" onClick={() => gotoEditReport(report.id)}>
            Edit report
          </Button>
          <Button variant="default" onClick={() => gotoReport(report.id)}>
            {report.status === 'draft' ? 'Preview' : 'View report'}
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};
