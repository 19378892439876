import {
  UnstyledButton,
  Checkbox,
  Radio,
  Text,
  Image,
  createStyles,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = createStyles((theme, { checked }: { checked: boolean }) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transition: 'background-color 150ms ease, border-color 150ms ease',
    border: `1px solid ${
      checked
        ? theme.fn.variant({ variant: 'outline', color: theme.primaryColor })
            .border
        : theme.colorScheme === 'dark'
        ? theme.colors.dark[5]
        : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.sm,
    backgroundColor: checked
      ? theme.fn.variant({ variant: 'light', color: theme.primaryColor })
          .background
      : theme.colorScheme === 'dark'
      ? theme.colors.dark[7]
      : theme.white,
  },

  body: {
    flex: 1,
    marginLeft: theme.spacing.md,
    marginRight: theme.spacing.md,
  },
}));

interface ImageCheckboxProps {
  value: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?(checked: boolean): void;
  title: string;
  description?: string;
  className?: string;
  image?: string;
  icon?: any;
  handleChange: any;
  isRadio?: boolean;
}

export function ImageCheckbox({
  value,
  checked,
  defaultChecked,
  onChange,
  title,
  description,
  className,
  image,
  icon,
  handleChange,
  isRadio,
  ...others
}: ImageCheckboxProps) {
  const { classes, cx } = useStyles({ checked: checked || false });

  return (
    <UnstyledButton
      {...others}
      onClick={() => handleChange(value)}
      className={cx(classes.button, className)}
    >
      {image && <Image src={image} alt={title} width={40} />}
      {icon && <FontAwesomeIcon icon={['fass', icon]} size="xl" />}

      <div className={classes.body}>
        <Text color="dimmed" size="xs" sx={{ lineHeight: 1 }} mb={5}>
          {description}
        </Text>
        <Text weight={500} size="sm" sx={{ lineHeight: 1 }}>
          {title}
        </Text>
      </div>

      {isRadio ? (
        <Radio
          value={value}
          checked={checked}
          onChange={() => handleChange(value)}
          tabIndex={-1}
          styles={{ radio: { cursor: 'pointer' } }}
        />
      ) : (
        <Checkbox
          checked={checked}
          onChange={() => handleChange(value)}
          tabIndex={-1}
          styles={{ input: { cursor: 'pointer' } }}
        />
      )}
    </UnstyledButton>
  );
}
