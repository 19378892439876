import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Homepage } from './pages/homepage';
import { LoginPage } from './pages/login';
import { LogoutPage } from './pages/logout';
import { LoginSuccessPage } from './pages/loginSuccess';
import { OnboardingPage } from './pages/onboarding';
import { CampaignPage } from './pages/campaign';
import { SettingsPage } from './pages/settings';
import { FeedbackPage } from './pages/feedback';
import { PlatformFeedbackPage } from './pages/platformFeedback';
import { UsersPage } from './pages/users';
import { UserProfilePage } from './pages/userProfile';
import { ReportsPage } from './pages/reports';
import { NewReportPage } from './pages/newReport';
import { EditReportPage } from './pages/editReport';
import { ViewReportPage } from './pages/viewReport';
import { FormPage } from './pages/form';
import { GenericLoadingScreen } from './components/genericLoadingScreen';
import { ROLE_ADMIN, ROLE_USER, ROLE_GUEST } from '@emvest/schema';
import { useLocation } from 'react-router-dom';
import intersection from 'lodash/intersection';
import useAuthWatcher from './utils/authWatcher';
import { getThemeColor } from './store/interface';
import { useSelector } from 'react-redux';
import { ProfileStateInterface, getProfile } from './store/profile';
import get from 'lodash/get';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fass } from '@fortawesome/sharp-solid-svg-icons';
import Celebration from './components/celebration';
import ScrollToTop from './utils/scrollToTop';

library.add(fass);

const customTheme: MantineThemeOverride = {
  colors: {
    emvestPurple: [
      '#F6EAFB',
      '#E5C3F4',
      '#D49DEC',
      '#C476E5',
      '#B34FDD',
      '#A329D6',
      '#8221AB',
      '#621980',
      '#411056',
      '#21082B',
    ],
  },
  primaryColor: 'emvestPurple',
  primaryShade: 6,
  loader: 'bars',
  // lineHeight: 2.5,
};

function App() {
  useAuthWatcher();
  const themeColor = useSelector(getThemeColor);

  // return routes
  return (
    <MantineProvider
      theme={{ ...customTheme, colorScheme: themeColor }}
      withGlobalStyles
      withNormalizeCSS
    >
      <Celebration />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* WELCOME */}
          <Route path="/landing" element={<CampaignPage />} />

          {/* AUTH0 INTEGRATION */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/login-success" element={<LoginSuccessPage />} />

          {/* ONBOARDING */}
          <Route
            path="/onboarding"
            element={
              <ProtectedRoute
                roles={[ROLE_USER, ROLE_ADMIN]}
                outlet={<OnboardingPage />}
              />
            }
          />
          <Route
            path="/onboarding/:step"
            element={
              <ProtectedRoute
                roles={[ROLE_USER, ROLE_ADMIN]}
                outlet={<OnboardingPage />}
              />
            }
          />

          {/* CORE */}
          {/* <Route
            index
            element={
              <ProtectedRoute
                roles={[ROLE_USER, ROLE_ADMIN]}
                outlet={<Homepage />}
              />
            }
          /> */}
          <Route path="/" element={<Homepage />} />
          <Route
            path="/feedback"
            element={
              <ProtectedRoute
                roles={[ROLE_ADMIN, ROLE_USER]}
                outlet={<FeedbackPage />}
              />
            }
          />
          <Route
            path="/forms/:formId"
            element={
              <ProtectedRoute
                roles={[ROLE_ADMIN, ROLE_USER]}
                outlet={<FormPage />}
              />
            }
          />
          <Route
            path="/admin/users"
            element={
              <ProtectedRoute roles={[ROLE_ADMIN]} outlet={<UsersPage />} />
            }
          />
          <Route
            path="/admin/users/:userId"
            element={
              <ProtectedRoute
                roles={[ROLE_ADMIN]}
                outlet={<UserProfilePage />}
              />
            }
          />
          <Route
            path="/admin/reports"
            element={
              <ProtectedRoute roles={[ROLE_ADMIN]} outlet={<ReportsPage />} />
            }
          />
          <Route
            path="/admin/reports/new"
            element={
              <ProtectedRoute roles={[ROLE_ADMIN]} outlet={<NewReportPage />} />
            }
          />
          <Route
            path="/admin/reports/new/:userId"
            element={
              <ProtectedRoute roles={[ROLE_ADMIN]} outlet={<NewReportPage />} />
            }
          />
          <Route
            path="/admin/reports/edit/:reportId"
            element={
              <ProtectedRoute
                roles={[ROLE_ADMIN]}
                outlet={<EditReportPage />}
              />
            }
          />
          <Route
            path="/reports/:reportId"
            element={
              <ProtectedRoute
                roles={[ROLE_ADMIN, ROLE_USER]}
                outlet={<ViewReportPage />}
              />
            }
          />
          <Route
            path="/admin/feedback"
            element={
              <ProtectedRoute
                roles={[ROLE_ADMIN]}
                outlet={<PlatformFeedbackPage />}
              />
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute
                roles={[ROLE_ADMIN, ROLE_USER]}
                outlet={<SettingsPage />}
              />
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute roles={[ROLE_ADMIN]} outlet={<Homepage />} />
            }
          />
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  );
}

type ProtectedRouteProps = {
  roles: string[];
  outlet: JSX.Element;
};
const ProtectedRoute = ({ roles, outlet }: ProtectedRouteProps) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const profile: ProfileStateInterface = useSelector(getProfile);
  const location = useLocation();

  // check if route allows guest access
  if (roles.includes(ROLE_GUEST)) return outlet;

  // if user is not authenticated
  if (!isAuthenticated) {
    // check if auth is loading
    if (isLoading) return <GenericLoadingScreen />;

    // redirect to login if user is not logged in
    return <Navigate to={`/login?redirect=${location.pathname}`} />;
  }

  // redirect user to homepage if user doesn't have role access to this route
  const userRoles = get(user, ['https://api.emvest.ai/roles'], []);
  const roleIntersection = intersection(roles, userRoles);
  if (roleIntersection.length === 0) return <Navigate to="/" />;

  // enforce onboarding steps
  if (
    profile.loggedIn &&
    !profile.onboardingComplete &&
    !location.pathname.includes('/onboarding')
  ) {
    return <Navigate to={`/onboarding/${profile.onboardingStep}`} />;
  }

  // enforce onboarding completion
  if (
    (!profile.loggedIn || profile.onboardingComplete) &&
    location.pathname.includes('/onboarding')
  ) {
    return <Navigate to={'/'} />;
  }

  // return route outlet
  return outlet;
};

export default App;
