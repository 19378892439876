import React, { useEffect, useState } from 'react';
import Page from '../../components/page';
import {
  Button,
  Grid,
  Stack,
  Card,
  Text,
  Group,
  Title,
  SimpleGrid,
  Space,
  Image,
} from '@mantine/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/sharp-solid-svg-icons';
import WaitingImage from './waiting-image.png';

export const UserDashboard = () => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const gotoNewsletterSignup = () => {
    const newsletterUrl = 'https://emvest.ai/the-best-investing-newsletter-2/';
    window.open(newsletterUrl, '_blank') ||
      window.location.replace(newsletterUrl);
  };

  // dashboard
  return (
    <Page>
      <Title>Hi {profile.firstName}!</Title>
      <Space h="xl" />
      <Grid grow columns={4} gutter="xl">
        <Grid.Col span={1}>
          <Image src={WaitingImage} alt="emvest.ai" width="100%" />
        </Grid.Col>
        <Grid.Col span={3}>
          <Card p="lg" radius="md" withBorder>
            <Stack>
              <Title order={3}>Stay tuned...</Title>
              <Text>
                Our financial experts are hard at work reviewing your portfolio
                and goals.
              </Text>
              <Text>
                We'll send you an email as soon as your report is ready!
                <Space h="xs" />
              </Text>
              <Group>
                <Button
                  variant="filled"
                  onClick={() => navigate('/forms/intro-quiz')}
                >
                  Take our 'getting started' quiz
                </Button>
              </Group>
            </Stack>
          </Card>
        </Grid.Col>
      </Grid>

      <Space h="xl" />
      <Space h="xl" />
      <Title order={2} sx={{ opacity: 0.5 }}>
        In the meantime:
      </Title>
      <Space h="xl" />

      <SimpleGrid cols={2}>
        <Card p="lg" radius="md" withBorder>
          <Stack>
            <Title order={3}>Weekly newsletter</Title>
            <Text>
              Fresh financial news and market analysis in your inbox every
              Tuesday!
              <Space h="xs" />
            </Text>
            <Group position="right">
              <Button
                fullWidth
                variant="default"
                onClick={gotoNewsletterSignup}
                rightIcon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
              >
                Signup for the newsletter
              </Button>
            </Group>
          </Stack>
        </Card>
        <Card p="lg" radius="md" withBorder>
          <Stack>
            <Title order={3}>Enjoying the beta?</Title>
            <Text>
              Send us your thoughts and ideas with our feedback form.
              <Space h="xs" />
            </Text>
            <Group position="right">
              <Button
                fullWidth
                variant="default"
                onClick={() => navigate('/feedback')}
              >
                Provide feedback
              </Button>
            </Group>
          </Stack>
        </Card>
      </SimpleGrid>
    </Page>
  );
};
