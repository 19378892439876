import React, { useEffect } from 'react';
import Page from '../../components/page';
import { useAuth0 } from '@auth0/auth0-react';
import { Title, Text, Button } from '@mantine/core';
import { useNavigate, useLocation } from 'react-router-dom';
// import emvestApi from '../../utils/emvestApi';
import { useSelector } from 'react-redux';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import { setCelebration } from '../../store/celebration';
import { useAppDispatch } from '../../store/hooks';
import { AdminDashboard } from './adminDashboard';
import { UserDashboard } from './userDashboard';
import get from 'lodash/get';

export const Homepage = () => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user, isLoading, isAuthenticated } = useAuth0();
  const params = new URLSearchParams(location.search);
  const auth0Code = params.get('code') || null;

  useEffect(() => {
    if (!auth0Code) {
      if (!isAuthenticated) {
        // redirect to campaign landing page
        if (!isLoading) {
          navigate('/landing', { replace: true });
        }
      } else {
        // enforce onboarding steps
        if (profile.loggedIn && !profile.onboardingComplete) {
          navigate(`/onboarding/${profile.onboardingStep}`, { replace: true });
        }
      }
    }
  }, [auth0Code, location, isLoading, isAuthenticated, profile, navigate]);

  const userEmail = get(user, ['email'], '');
  const userRoles = get(user, ['https://api.emvest.ai/roles'], []);
  const firstRole = userRoles.length ? userRoles[0] : '';

  const celebrate = async () => {
    dispatch(
      setCelebration({
        open: true,
        title: `Great work ${profile.firstName}! 😊`,
        message:
          'You\'re all set to go. Welcome to emvest!\n\nWe\'ll get back to you in the next couple days with your personal portfolio analysis.\n\nIn the meantime, feel free to explore your personal emvest.ai dashboard and take our "introduction to investing" quiz!',
        buttonText: 'My dashboard',
      })
    );
  };

  // loading state between redirects
  if (
    auth0Code ||
    isLoading ||
    !isAuthenticated ||
    (isAuthenticated && !profile.onboardingComplete)
  )
    return <Page></Page>;

  // admin dashboard
  if (userRoles.includes('admin')) return <AdminDashboard />;

  // dashboard
  return <UserDashboard />;
};
