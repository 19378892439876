import React, { useEffect } from 'react';
import Page from '../../components/page';
import { Container, Space, Stepper } from '@mantine/core';
import { useParams, useNavigate } from 'react-router-dom';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faUserVneckHairLong,
  faTrophy,
  faChartPieSimple,
} from '@fortawesome/sharp-solid-svg-icons';
import { OnboardingStepOne } from './stepOne';
import { OnboardingStepTwo } from './stepTwo';
import { OnboardingStepThree } from './stepThree';

export type OnboardingComponentProps = {};

export const OnboardingPage = () => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const { step } = useParams();
  const stepIndex = parseInt(step || '0', 10);
  const navigate = useNavigate();

  useEffect(() => {
    // enforce step param
    if (stepIndex < 1 || stepIndex > profile.onboardingStep) {
      navigate(`/onboarding/${profile.onboardingStep}`, { replace: true });
    }
  }, [stepIndex, profile, navigate]);

  const handleStepClick = (stepId: number) => {
    navigate(`/onboarding/${stepId + 1}`);
  };

  // loading first step
  if (!step) return <Page />;

  // onboarding
  return (
    <Page>
      <Container>
        <Stepper
          iconSize={50}
          onStepClick={handleStepClick}
          active={stepIndex - 1}
        >
          <Stepper.Step
            icon={<FontAwesomeIcon icon={faUserVneckHairLong} size="lg" />}
            completedIcon={<FontAwesomeIcon icon={faCheck} size="lg" />}
            allowStepSelect={profile.onboardingStep >= 1}
            label="Step 1"
            description="Account details"
          />
          <Stepper.Step
            icon={<FontAwesomeIcon icon={faTrophy} size="lg" />}
            completedIcon={<FontAwesomeIcon icon={faCheck} size="lg" />}
            allowStepSelect={profile.onboardingStep >= 2}
            label="Step 2"
            description="Life goals"
          />
          <Stepper.Step
            icon={<FontAwesomeIcon icon={faChartPieSimple} size="lg" />}
            completedIcon={<FontAwesomeIcon icon={faCheck} size="lg" />}
            allowStepSelect={profile.onboardingStep >= 3}
            label="Step 3"
            description="Portfolio snapshot"
          />
        </Stepper>
      </Container>

      <Space h="xl" />
      <Container>
        {stepIndex === 1 && <OnboardingStepOne />}
        {stepIndex === 2 && <OnboardingStepTwo />}
        {stepIndex === 3 && <OnboardingStepThree />}
      </Container>
    </Page>
  );
};
