import React from 'react';
import { createStyles, Container, Center, Paper } from '@mantine/core';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

interface LoginOptions {
  screen_hint?: string;
  appState?: any;
}

const useStyles = createStyles((theme) => ({
  background: {
    background: '#efefef',
    height: '100vh',
    overflow: 'hidden',
  },
  paper: {
    marginTop: '92px',
    marginBottom: '600px',
    height: '600px',
    width: '100%',
    maxWidth: '400px',
    padding: '40px',
  },
}));

export const LoginSkeleton = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.background}>
      <Container>
        <Center>
          <Paper className={classes.paper} shadow="xs"></Paper>
        </Center>
      </Container>
    </div>
  );
};

export const LoginPage = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();

  // auth0 setting up user session
  if (isLoading) return <LoginSkeleton />;

  // user is authenticated - redirect to homepage
  if (isAuthenticated) return <Navigate to="/" replace />;

  // user is not authenticated - redirect to universal login
  const auth0LoginOptions: LoginOptions = {};
  const params = new URLSearchParams(location.search);
  const shouldSignup: boolean = Boolean(params.get('signup')) || false;
  const redirectTarget =
    '/login-success?redirect=' + (params.get('redirect') || '/');
  if (redirectTarget) {
    auth0LoginOptions['appState'] = {
      returnTo: redirectTarget,
    };
  }
  if (shouldSignup) {
    auth0LoginOptions['screen_hint'] = 'signup';
  }
  loginWithRedirect(auth0LoginOptions);
  // loginWithRedirect({ screen_hint: 'signup' });

  return <LoginSkeleton />;
};
