import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OnboardingStateInterface {
  campaignId: string;
  name: string;
  email: string;
}
export const initialOnboardingState: OnboardingStateInterface = {
  campaignId: '',
  name: '',
  email: '',
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialOnboardingState,
  reducers: {
    updateOnboarding: (
      state,
      action: PayloadAction<OnboardingStateInterface>
    ) => {
      return { ...action.payload };
    },
    resetOnboarding: () => {
      return initialOnboardingState;
    },
  },
});
export const { updateOnboarding, resetOnboarding } = onboardingSlice.actions;
export default onboardingSlice.reducer;
export * from './selectors';
