import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CelebrationStateInterface {
  open: boolean;
  title: string;
  message: string;
  buttonText?: string;
}
export const initialCelebrationState: CelebrationStateInterface = {
  open: false,
  title: '',
  message: '',
  buttonText: 'Continue',
};

export const celebrationSlice = createSlice({
  name: 'celebration',
  initialState: initialCelebrationState,
  reducers: {
    setCelebration: (
      state,
      action: PayloadAction<CelebrationStateInterface>
    ) => {
      return {
        ...initialCelebrationState,
        ...action.payload,
      };
    },
    resetCelebration: () => {
      return initialCelebrationState;
    },
  },
});
export const { setCelebration, resetCelebration } = celebrationSlice.actions;
export default celebrationSlice.reducer;
export * from './selectors';
