import React, { useState } from 'react';
import {
  Alert,
  Stack,
  Space,
  Title,
  Button,
  Textarea,
  Group,
  Breadcrumbs,
  Text,
  Anchor,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ProfileStateInterface, getProfile } from '../../store/profile';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/sharp-solid-svg-icons';
import Page from '../../components/page';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emvestApi from '../../utils/emvestApi';
import { CreateFeedback } from '@emvest/schema';
import { useAppDispatch } from '../../store/hooks';
import { setCelebration } from '../../store/celebration';

interface IFormInput {
  feedback: string;
}
const schema = yup
  .object({
    feedback: yup
      .string()
      .required('Please write a message in order to send feedback'),
  })
  .required();

export const FeedbackPage = () => {
  const profile: ProfileStateInterface = useSelector(getProfile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useAppDispatch();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setLoading(true);

    try {
      // send updated user data to API
      const feedbackArgs: CreateFeedback = {
        message: data.feedback,
      };
      const response = await emvestApi.post('/feedback', feedbackArgs);
      if (typeof response === 'object') {
        setSuccess(true);
        setValue('feedback', '');
        dispatch(
          setCelebration({
            open: true,
            title: `Thanks ${profile.firstName}!`,
            message:
              "Thanks for sending your feedback! We'll review it carefully.",
          })
        );
        // setTimeout(() => {
        //   navigate('/');
        // }, 3000);
      } else {
        throw new Error('Response is not an object');
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        <Text color="dimmed">Send feedback</Text>
      </Breadcrumbs>
      <Space h="xl" />
      <Group sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>Send feedback</Title>
        <Button variant="default" onClick={() => navigate('/')}>
          Back to dashboard
        </Button>
      </Group>
      <Space h="xl" />

      {success && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faCircleCheck} />}
            title={`Thanks ${profile.firstName}!`}
            color="green"
            variant="outline"
          >
            Thanks for sending your feedback! You rock.
          </Alert>
          <Space h="md" />
          <Group>
            <Button variant="default" onClick={() => setSuccess(false)}>
              Submit more feedback
            </Button>
            <Button onClick={() => navigate('/')}>Back to dashboard</Button>
          </Group>
        </>
      )}

      {error && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
            title="Something went wrong"
            color="red"
          >
            Sorry for the inconvenience - please try again or reach out to our
            support team at support@emvest.ai.
          </Alert>
          <Space h="md" />
        </>
      )}

      {!success && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Textarea
              label="Your feedback"
              description="We welcome any and all feedback. Thank you for testing the emvest.ai beta with us!"
              placeholder="Feedback"
              disabled={loading}
              error={errors.feedback?.message}
              minRows={4}
              {...register('feedback')}
            />

            <Group position="right" mt="md">
              <Button type="submit" loading={loading}>
                Send feedback
              </Button>
            </Group>
          </Stack>
        </form>
      )}
    </Page>
  );
};
