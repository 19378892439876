import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/page';
import {
  SimpleGrid,
  Text,
  Space,
  Title,
  Skeleton,
  Alert,
  Breadcrumbs,
  Anchor,
  Button,
  Group,
} from '@mantine/core';
import emvestApi from '../../utils/emvestApi';
import { ReportInterface, ReadReports } from '@emvest/schema';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSnooze,
  faExclamationTriangle,
} from '@fortawesome/sharp-solid-svg-icons';
import { ReportCard } from '../../components/reportCard';

export const ReportsPage = () => {
  const [reports, setReports] = useState<ReportInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const args: ReadReports = {
          sortBy: 'created',
          sortOrder: 'DESC',
          limit: 100,
        };
        const response = await emvestApi.get('/reports', args);
        const results = get(response, ['results'], []);
        setReports(results);
      } catch (e) {
        console.error(e);
        setError(true);
      }
      setLoading(false);
    };
    fetchReports();
  }, []);

  const gotoNewReport = () => {
    navigate(`/admin/reports/new`);
  };

  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        <Text color="dimmed">Reports</Text>
      </Breadcrumbs>
      <Space h="xl" />
      <Group sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>Reports</Title>
        <Button variant="filled" onClick={gotoNewReport}>
          New report
        </Button>
      </Group>
      <Space h="xl" />

      <SimpleGrid cols={2}>
        {reports.map((report) => (
          <div key={report.id}>
            <ReportCard report={report} />
          </div>
        ))}
      </SimpleGrid>

      {loading && <Skeleton animate={true} height={274} width="100%" />}
      {!loading && !error && !reports.length && (
        <>
          <Space h="xl" />
          <Alert
            title="No reports found"
            color="yellow"
            variant="outline"
            icon={<FontAwesomeIcon icon={faSnooze} />}
          >
            Please check back later
          </Alert>
        </>
      )}
      {error && (
        <>
          <Space h="xl" />
          <Alert
            title="Something went wrong"
            color="red"
            variant="outline"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          >
            Please check back later
          </Alert>
        </>
      )}
    </Page>
  );
};
