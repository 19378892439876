import { useSelector } from 'react-redux';
import {
  CelebrationStateInterface,
  getCelebration,
} from '../../store/celebration';
import ParticleMessage from './particleMessage';

export default function Celebration() {
  const celebration: CelebrationStateInterface = useSelector(getCelebration);

  return (
    <ParticleMessage
      open={celebration.open}
      title={celebration.title}
      message={celebration.message}
      buttonText={celebration.buttonText}
    />
  );
}
