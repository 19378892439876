import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInterface } from '@emvest/schema';

export interface ProfileStateInterface extends UserInterface {
  loggedIn?: boolean;
}
export const initialProfileState: ProfileStateInterface = {
  loggedIn: false,
  id: '',
  username: '',
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  photo: '',
  emailVerified: false,
  phoneVerified: false,
  onboardingStep: 1,
  onboardingComplete: false,
  created: '',
  updated: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialProfileState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfileStateInterface>) => {
      return { ...action.payload, loggedIn: true };
    },
    resetProfile: () => {
      return initialProfileState;
    },
  },
});
export const { setProfile, resetProfile } = profileSlice.actions;
export default profileSlice.reducer;
export * from './selectors';
