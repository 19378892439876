import { useCallback } from 'react';
import {
  Container,
  Overlay,
  Modal,
  Title,
  Text,
  Button,
  Group,
  Space,
} from '@mantine/core';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import type { Engine, ISourceOptions } from 'tsparticles-engine';
import {
  CelebrationStateInterface,
  resetCelebration,
} from '../../../store/celebration';
import { useAppDispatch } from '../../../store/hooks';
import particlesOptions from './particles';

export default function ParticleMessage({
  open,
  title,
  message,
  buttonText,
}: CelebrationStateInterface) {
  const dispatch = useAppDispatch();

  const particlesInit = useCallback(async (engine: Engine) => {
    // console.log(engine);

    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  // const particlesLoaded = useCallback(async (container: any) => {
  // await console.log(container);
  // }, []);

  const onClose = () => {
    dispatch(resetCelebration());
  };

  if (!open) return null;

  return (
    <>
      <Overlay opacity={0.6} color={'#000'} zIndex={500} />

      <Container sx={{ position: 'relative', zIndex: 700 }}>
        <Particles
          options={{
            ...(particlesOptions as ISourceOptions),
            pauseOnOutsideViewport: true,
          }}
          init={particlesInit}
        />
      </Container>

      <Modal
        opened={true}
        title={<Title>{title}</Title>}
        centered={true}
        size="lg"
        onClose={onClose}
        overlayOpacity={0}
        zIndex={900}
      >
        <Space h={'sm'} />
        <Text size={'md'} sx={{ whiteSpace: 'pre-wrap' }}>
          {message}
        </Text>
        <Space h={'xl'} />

        <Group position="right">
          <Button onClick={onClose}>{buttonText}</Button>
        </Group>
      </Modal>
    </>
  );
}
