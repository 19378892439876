import { Title, Space, Loader } from '@mantine/core';
import Page from '../page';

export function GenericLoadingScreen() {
  return (
    <Page>
      <Title>Logging in...</Title>
      <Space h="md" />
      <Loader />
    </Page>
  );
}
