export const promptMap: any = {
  ageBracket: 'Age bracket',
  closenessDetails: 'Context for your goal closeness',
  closenessRating: 'How close do you feel to reaching these goals?',
  selectedBrokers: 'Where are your investment accounts?',
  selectedGoals: 'What are your financial goals for the next 3 years?',
  selectedInvestments: 'What types of investment accounts do you have?',
  portfolioSize: 'Estimated dollars invested',
  selectedTickers: 'Share your investments for us to analyze',
};
