import { CheckboxOptions } from '../../../components/form/checkboxGroup';

const financialGoalOptions: CheckboxOptions[] = [
  {
    value: 'credit',
    label: 'Pay off credit cards',
    description: 'Test description',
    style: 'icon',
    icon: 'credit-card',
  },
  {
    value: 'student-loans',
    label: 'Pay off student loans',
    description: 'Test description',
    style: 'icon',
    icon: 'graduation-cap',
  },
  {
    value: 'freedom',
    label: 'Financial freedom',
    description: 'Test description',
    style: 'icon',
    icon: 'plane-departure',
  },
  {
    value: 'car',
    label: 'Buy a car',
    description: 'Test description',
    style: 'icon',
    icon: 'car',
  },
  {
    value: 'house',
    label: 'Buy a house',
    description: 'Test description',
    style: 'icon',
    icon: 'sign-hanging',
  },
  {
    value: 'kids',
    label: 'Pay for kids',
    description: 'Test description',
    style: 'icon',
    icon: 'children',
  },
  {
    value: 'retire',
    label: 'Retirement',
    description: 'Test description',
    style: 'icon',
    icon: 'party-horn',
  },
  {
    value: 'vacation',
    label: 'Vacation',
    description: 'Test description',
    style: 'icon',
    icon: 'island-tropical',
  },
];

export default financialGoalOptions;
