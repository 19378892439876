import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InputStateInterface {
  [key: string]: FormInterface;
}
export interface FormInterface {
  formId: string;
  data: any;
}

export const initialInputState: InputStateInterface = {};

export const inputSlice = createSlice({
  name: 'input',
  initialState: initialInputState,
  reducers: {
    saveForm: (state, action: PayloadAction<FormInterface>) => {
      return {
        ...state,
        [action.payload.formId]: action.payload.data,
      };
    },
    resetForms: () => {
      return initialInputState;
    },
  },
});
export const { saveForm, resetForms } = inputSlice.actions;
export default inputSlice.reducer;
export * from './selectors';
