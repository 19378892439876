import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../components/page';
import {
  Select,
  SimpleGrid,
  Loader,
  Card,
  Text,
  Space,
  Title,
  Stack,
  Skeleton,
  Alert,
  Breadcrumbs,
  Anchor,
  Button,
  Group,
  Table,
  Badge,
  Box,
} from '@mantine/core';
import emvestApi from '../../utils/emvestApi';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  UserInterface,
  ReadUsers,
  ReportTypeEnum,
  CreateReport,
} from '@emvest/schema';
import format from 'date-fns/format';
import get from 'lodash/get';
import { UserSummary } from '../../components/userSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initialProfileState } from '../../store/profile';
import {
  faCircleCheck,
  faExclamationTriangle,
  faCircleNotch,
  faList,
  faUser,
} from '@fortawesome/sharp-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface IFormInput {
  reportType: ReportTypeEnum;
}
type SelectUserOption = {
  value: string;
  label: string;
};

export const EditReportPage = () => {
  const { userId, reportId } = useParams();
  const [users, setUsers] = useState<SelectUserOption[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const schema = yup
    .object({
      reportType: yup.string().required('This field is required'),
    })
    .required();
  const defaults: IFormInput = {
    reportType: 'analysis',
  };
  const {
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: 'onBlur',
    defaultValues: defaults,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    // if default userId is provided in URL, fetch user info
    const fetchUserInfo = async () => {
      setLoadingUsers(true);
      const args: ReadUsers = {
        id: userId,
      };
      const response = await emvestApi.get('/users', args);
      if (typeof response === 'object') {
        const returnedUser = get(response, ['results', 0], null);
        const defaultUserInfo: UserInterface = {
          ...initialProfileState,
          ...returnedUser,
        };
        setUsers([
          {
            value: String(userId),
            label: defaultUserInfo.firstName + ' ' + defaultUserInfo.lastName,
          },
        ]);
        setLoadingUsers(false);
      }
    };
    if (userId) {
      fetchUserInfo();
    }
  }, [userId]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setSaving(true);
    // try {
    //   // submit form data to api
    //   const args: CreateReport = {
    //     userId: data.forUserId,
    //     type: data.reportType,
    //     reportJSON: null,
    //   };
    //   const response = await emvestApi.post('/reports', args);

    //   // save updated data to redux
    //   if (response) {
    //     // navigate to new report page
    //     const newReportId = get(response, ['id'], null);
    //     setTimeout(() => {
    //       navigate(`/admin/reports/edit/${newReportId}`);
    //     }, 1500);
    //   }
    //   setSuccess(true);
    // } catch (error) {
    //   console.error(error);
    //   setError(true);
    //   setSaving(false);
    // }
  };

  const gotoReport = () => {
    navigate(`/reports/${reportId}`);
  };

  const reportType = watch('reportType');

  return (
    <Page>
      <Breadcrumbs>
        <Anchor href="/">Dashboard</Anchor>
        <Anchor href="/admin/reports">Reports</Anchor>
        <Text color="dimmed">Edit report</Text>
      </Breadcrumbs>
      <Space h="xl" />
      <Group sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Title>Edit report</Title>
        <Button variant="default" onClick={gotoReport}>
          {true ? 'Preview' : 'View report'}
        </Button>
      </Group>
      <Space h="xl" />

      {success && (
        <>
          <Alert
            icon={<FontAwesomeIcon icon={faCircleCheck} />}
            title={'Draft created'}
            color="green"
            variant="outline"
          >
            A draft of this form has successfully been created.
          </Alert>
          <Space h="md" />
        </>
      )}

      {error && (
        <>
          <Space h="xl" />
          <Alert
            title="Something went wrong"
            color="red"
            variant="outline"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}
          >
            Please try again later
          </Alert>
          <Space h="xl" />
        </>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Select
            label="What type of report will this be?"
            data={[
              { value: 'analysis', label: 'Portfolio analysis' },
              { value: 'suggestion', label: 'Stock suggestions' },
            ]}
            value={reportType}
            onChange={(newReportType: ReportTypeEnum) =>
              setValue('reportType', newReportType)
            }
            disabled={saving}
            error={errors.reportType?.message}
            icon={<FontAwesomeIcon icon={faList} />}
          />

          <Group position="right" mt="md">
            <Button type="submit" loading={saving}>
              Save changes
            </Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
